import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {

    const stories = [
        { imgSrc: "/assets/images/family_goals_planning_couple.png", time: "December 8th, 2024", title: "How Are Major Family Goals Planned Together", content: "Discover how Muslim families plan and achieve major family goals together.", link: "/how-are-major-family-goals-planned-together" },
        { imgSrc: "/assets/images/muslim_couple_in_love.png", time: "December 8th, 2024", title: "How Married Couples Can Honor Their Promises", content: "Learn the importance of honoring promises in a successful Muslim marriage.", link: "/how-married-couples-can-honor-their-promises" },
        { imgSrc: "/assets/images/committed_muslim_family.png", time: "December 8th, 2024", title: "How Commitment is Shown Within the Family", content: "Explore how commitment strengthens the bonds within a Muslim family.", link: "/how-commitment-is-shown-within-the-family" },
        { imgSrc: "/assets/images/faith_uplifting_couple.png", time: "December 8th, 2024", title: "How Can a Husband Uplift His Wife's Faith", content: "Learn how husbands can uplift and support their wife's faith in Islam.", link: "/how-can-a-husband-uplift-his-wifes-faith" },
        { imgSrc: "/assets/images/muslim_family_mercy.png", time: "December 8th, 2024", title: "How Mercy is Encouraged in Family Relations", content: "Discover how mercy fosters harmony and unity in family relationships.", link: "/how-mercy-is-encouraged-in-family-relations" },
        { imgSrc: "/assets/images/family_dispute_resolution.png", time: "December 8th, 2024", title: "How Family Disputes are Resolved", content: "Explore Islamic principles on how to resolve family disputes peacefully.", link: "/how-family-disputes-are-resolved" },
        { imgSrc: "/assets/images/family_boundaries_respected_image.png", time: "December 8th, 2024", title: "How Are Boundaries Respected in Family Traditions", content: "Learn the role of respect for boundaries in maintaining family harmony.", link: "/how-are-boundaries-respected-in-family-traditions" },
        { imgSrc: "/assets/images/faithful_muslim_family.png", time: "December 8th, 2024", title: "How Family Cooperation is Encouraged in Islam", content: "Discover how family cooperation is fostered and encouraged in Islam.", link: "/how-family-cooperation-is-encouraged-in-islam" },
        { imgSrc: "/assets/images/family_involvement_muslim_couple.png", time: "December 8th, 2024", title: "How Community Involvement is Encouraged in Family Life", content: "Learn how community involvement strengthens family ties in Islam.", link: "/how-community-involvement-is-encouraged-in-family-life" },
        { imgSrc: "/assets/images/family_celebration_halal_way.png", time: "December 8th, 2024", title: "How Family History is Celebrated in a Halal Way", content: "Explore how Muslim families celebrate their heritage and history in a halal way.", link: "/how-family-history-is-celebrated-in-a-halal-way" },
        { imgSrc: "/assets/images/family_generosity_display.png", time: "December 8th, 2024", title: "Generosity in the Family", content: "Discover how generosity plays a key role in strengthening family bonds.", link: "/generosity-in-the-family" },
        { imgSrc: "/assets/images/family_milestones_respectfully.png", time: "December 8th, 2024", title: "How to Approach Family Milestones Respectfully", content: "Learn how family milestones are approached respectfully in Islamic households.", link: "/how-to-approach-family-milestones-respectfully" },
        { imgSrc: "/assets/images/resolving_family_disagreements_privately.png", time: "December 8th, 2024", title: "How to Handle Familial Disagreements Privately", content: "Explore Islamic guidelines on how to handle family disagreements in private.", link: "/how-to-handle-familial-disagreements-privately" },
        { imgSrc: "/assets/images/children_religious_values.png", time: "December 8th, 2024", title: "How Are Children's Religious Values Shaped", content: "Discover how children's religious values are shaped in a Muslim family.", link: "/how-are-childrens-religious-values-shaped" },
        { imgSrc: "/assets/images/kind_approach_in_conflicts.png", time: "December 8th, 2024", title: "How to Approach Conflicts with Kindness", content: "Learn how kindness plays a role in resolving family conflicts peacefully.", link: "/how-to-approach-conflicts-with-kindness" },
        { imgSrc: "/assets/images/mutual_responsibilities_family_life.png", time: "December 8th, 2024", title: "How Mutual Responsibilities are Managed in Family Life", content: "Explore how mutual responsibilities are shared in Muslim family life.", link: "/how-mutual-responsibilities-family-life" },
        { imgSrc: "/assets/images/shared_memories_celebration_islam.png", time: "December 8th, 2024", title: "Shared Memories in Islam", content: "Discover how Muslim families create and celebrate shared memories together.", link: "/shared-memories-in-islam" },
        { imgSrc: "/assets/images/respect_family_privacy.png", time: "December 8th, 2024", title: "Respect Family Privacy", content: "Learn the importance of respecting family privacy in an Islamic household.", link: "/respect-family-privacy" },
        { imgSrc: "/assets/images/supportive_family_muslim_couple.png", time: "December 8th, 2024", title: "How Family Support Impacts Religious Growth", content: "Discover how family support strengthens religious growth in Islam.", link: "/how-family-support-impacts-religious-growth" },
        { imgSrc: "/assets/images/couple_guidance_main.png", time: "December 8th, 2024", title: "How Should Young Couples Seek Guidance", content: "Learn the importance of seeking guidance for young couples in Islam.", link: "/how-should-young-couples-seek-guidance" },
        { imgSrc: "/assets/images/mutual_responsibilities_family_life.png", time: "December 8th, 2024", title: "How Mutual Responsibilities are Managed in Family Life", content: "Explore how mutual responsibilities are shared in Muslim family life.", link: "/how-mutual-responsibilities-family-life" },
        { imgSrc: "/assets/images/grateful_family_blessings.png", time: "December 8th, 2024", title: "How Should Gratitude Be Shown for Family Blessings", content: "Learn how gratitude for family blessings is shown in Islam.", link: "/how-should-gratitude-be-shown-for-family-blessings" },
        { imgSrc: "/assets/images/muslim_couple_decision_making.png", time: "December 8th, 2024", title: "How Should Major Decisions Be Discussed Respectfully", content: "Discover the role of respect in discussing family decisions in Islam.", link: "/how-should-major-decisions-be-discussed-respectfully" },
        { imgSrc: "/assets/images/muslim_couple_disagreeing_gently.png", time: "December 8th, 2024", title: "How to Approach Disagreements with Gentleness", content: "Explore how gentleness can resolve family disagreements.", link: "/how-to-approach-disagreements-with-gentleness" },
        { imgSrc: "/assets/images/teaching_islam_to_youth.png", time: "December 8th, 2024", title: "How Islamic Principles Are Taught to Younger Generations", content: "Learn how Islamic principles are passed on to younger generations.", link: "/how-islamic-principles-are-taught-to-younger-generations" },
        { imgSrc: "/assets/images/faith_expressing_family.png", time: "December 8th, 2024", title: "How Can Family Express Faith Through Actions", content: "Discover how family members express their faith through daily actions.", link: "/how-can-family-express-faith-through-actions" },
        { imgSrc: "/assets/images/gratitude_marital_success.png", time: "December 8th, 2024", title: "Gratitude in Marital Success", content: "Discover how gratitude plays a vital role in achieving marital success in Sunni Islam.", link: "/gratitude-marital-success" },
        { imgSrc: "/assets/images/handling_inlaws_in_islam.png", time: "December 8th, 2024", title: "Handling In-Laws in Islam", content: "Learn how to manage relationships with in-laws according to Islamic principles.", link: "/handling-in-laws-in-islam" },
        { imgSrc: "/assets/images/halal_affection_expression.png", time: "December 8th, 2024", title: "How is Affection Expressed in Sunni Islam", content: "Explore how affection is shown in halal ways within a Sunni Islamic marriage.", link: "/how-is-affection-expressed-in-sunni-islam" },
        { imgSrc: "/assets/images/healthy_communication_sunni_marriage.png", time: "December 8th, 2024", title: "Healthy Communication Practices in Sunni Marriage", content: "Discover how healthy communication strengthens marriages in Sunni Islam.", link: "/healthy-communication-practices-sunni-marriage" },
        { imgSrc: "/assets/images/sunni_marital_intimacy.png", time: "December 8th, 2024", title: "How Should Marital Intimacy Be Approached in Sunni Islam", content: "Learn the Islamic perspective on marital intimacy and its significance in Sunni marriages.", link: "/how-should-marital-intimacy-be-approached-in-sunni-islam" },
        { imgSrc: "/assets/images/kindness_between_spouses.png", time: "December 8th, 2024", title: "Kindness Between Spouses", content: "Explore how kindness between spouses enhances love and mercy in marriage.", link: "/kindness-between-spouses" },
        { imgSrc: "/assets/images/household_chores_management_sunni_marriage.png", time: "December 8th, 2024", title: "Household Chores Management in Sunni Marriage", content: "Discover how household chores are managed in Sunni marriages for a balanced life.", link: "/household-chores-management-sunni-marriage" },
        { imgSrc: "/assets/images/family_unity_in_islam.png", time: "December 8th, 2024", title: "Strengthening Family Unity in Sunni Islam", content: "Learn how to strengthen unity in a Sunni Muslim family.", link: "/strengthening-family-unity-in-sunni-islam" },
        { imgSrc: "/assets/images/responsible_muslim_husband.png", time: "December 8th, 2024", title: "Husband's Responsibilities in Islam", content: "Explore the key responsibilities of a husband in Islamic family life.", link: "/husbands-responsibilities-in-islam" },
        { imgSrc: "/assets/images/trust_in_muslim_marriage.png", time: "December 8th, 2024", title: "How to Nurture Trust in Sunni Islamic Marriage", content: "Learn how to develop and nurture trust in a Sunni Islamic marriage.", link: "/how-to-nurture-trust-in-sunni-islamic-marriage" },
        { imgSrc: "/assets/images/sunni_household_responsibilities.png", time: "December 8th, 2024", title: "Responsibilities in Sunni Household", content: "Discover how responsibilities are shared and managed in a Sunni household.", link: "/responsibilities-in-sunni-household" },
        { imgSrc: "/assets/images/faithful_muslim_couple_discussing.png", time: "December 8th, 2024", title: "Faith in Marriage", content: "Explore how faith strengthens the bond between a husband and wife in Islam.", link: "/faith-in-marriage" },
        { imgSrc: "/assets/images/father_and_children_in_islam.png", time: "December 8th, 2024", title: "Father's Role in Raising Children in Islam", content: "Learn about the pivotal role of fathers in raising children in Islamic households.", link: "/fathers-role-in-raising-children-in-islam" },
        { imgSrc: "/assets/images/family_relationship_empathy.png", time: "December 8th, 2024", title: "How Empathy Can Improve Relationships Within Family", content: "Discover how empathy improves relationships and fosters love in family life.", link: "/how-empathy-can-improve-relationships-within-family" },
        { imgSrc: "/assets/images/happy_muslim_family_charity.png", time: "December 8th, 2024", title: "Charity and Family Happiness", content: "Explore how charity contributes to happiness and barakah in family life.", link: "/charity-and-family-happiness" },
        { imgSrc: "/assets/images/halal_family_holiday_celebration.png", time: "December 8th, 2024", title: "Family Holidays in Halal Way", content: "Learn how Muslim families celebrate holidays in a halal and Islamic manner.", link: "/family-holidays-in-halal-way" },
        { imgSrc: "/assets/images/honesty_between_parents_children.png", time: "December 8th, 2024", title: "Honesty Between Parents and Children", content: "Discover how honesty strengthens the relationship between parents and children.", link: "/honesty-between-parents-and-children" },
        { imgSrc: "/assets/images/family_heritage_muslim_couple.png", time: "December 8th, 2024", title: "Family Heritage in Islam", content: "Explore the importance of preserving and passing down family heritage in Islam.", link: "/family-heritage-in-islam" },
        { imgSrc: "/assets/images/sunni_marriage_harmony.png", time: "December 8th, 2024", title: "How Compromise Impacts Marital Harmony", content: "Learn how compromise strengthens marital harmony in Sunni Islam.", link: "/how-compromise-impacts-marital-harmony" },
        { imgSrc: "/assets/images/humility_in_sunni_family.png", time: "December 8th, 2024", title: "Role of Humility in Sunni Family Dynamics", content: "Discover the role of humility in fostering love and respect in a Sunni family.", link: "/role-of-humility-in-sunni-family-dynamics" },
        { imgSrc: "/assets/images/how_conflicts_over_finances_in_islam.png", time: "December 8th, 2024", title: "How Should Conflicts Over Finances Be Resolved in Islam", content: "Explore Islamic guidelines for resolving conflicts over finances in marriage.", link: "/how-should-conflicts-over-finances-be-resolved-in-islam" },
        { imgSrc: "/assets/images/mother_role_spirituality_impact.png", time: "December 8th, 2024", title: "Mother's Role Impacting Family Spirituality", content: "Learn how a mother's role impacts the spiritual well-being of the family.", link: "/mothers-role-impact-family-spirituality" },
        { imgSrc: "/assets/images/respectful-muslim-husband-in-laws.png", time: "December 8th, 2024", title: "Husband's Duties Towards In-Laws", content: "Discover the duties and responsibilities of a husband towards his in-laws.", link: "/husband-duties-towards-in-laws" },
        { imgSrc: "/assets/images/sunni_marriage_mutual_decision.png", time: "December 8th, 2024", title: "How Mutual Decision-Making is Encouraged in Sunni Marriage", content: "Learn how mutual decision-making strengthens marriage in Sunni Islam.", link: "/how-mutual-decision-making-is-encouraged-in-sunni-marriage" },
        { imgSrc: "/assets/images/muslim_family_gratitude.png", time: "December 8th, 2024", title: "How Families Nurture Gratitude", content: "Explore how families foster gratitude and appreciation for Allah's blessings.", link: "/how-families-nurture-gratitude" },
        { imgSrc: "/assets/images/family_generosity_practice.png", time: "December 8th, 2024", title: "How Can a Family Practice Generosity in Everyday Life", content: "Discover how families can integrate generosity into their daily lives.", link: "/how-can-a-family-practice-generosity-in-everyday-life" },
        { imgSrc: "/assets/images/community_support_in_sunni_marriages.png", time: "December 8th, 2024", title: "Community Support in Sunni Marriages", content: "Learn how community support strengthens Sunni marriages and family life.", link: "/community-support-in-sunni-marriages" },
        { imgSrc: "/assets/images/muslim_family_gathering_image.png", time: "December 8th, 2024", title: "Family Gatherings in Islam", content: "Explore how family gatherings are celebrated in accordance with Islamic values.", link: "/family-gatherings-in-islam" },
        { imgSrc: "/assets/images/gratitude_in_marriage.png", time: "December 8th, 2024", title: "Gratitude in Marriage in Sunni Islam", content: "Learn how gratitude impacts the success and happiness of marriage in Sunni Islam.", link: "/gratitude-in-marriage-in-sunni-islam" },
        { imgSrc: "/assets/images/disagreements_with_inlaws.png", time: "December 8th, 2024", title: "Disagreements About In-Laws", content: "Discover how to approach and resolve disagreements about in-laws according to Islamic teachings.", link: "/disagreements-about-in-laws" },
        { imgSrc: "/assets/images/affection_in_sunni_islam.png", time: "December 8th, 2024", title: "Affection in Sunni Islam", content: "Explore how affection is expressed and maintained within Sunni Muslim marriages.", link: "/affection-in-sunni-islam" },
        { imgSrc: "/assets/images/healthy_communication_in_sunni_marriage.png", time: "December 8th, 2024", title: "Healthy Communication in Sunni Marriage", content: "Learn the importance of healthy communication for a successful Sunni Muslim marriage.", link: "/healthy-communication-sunni-marriage" },
        { imgSrc: "/assets/images/muslim_couple_marital_intimacy.png", time: "December 8th, 2024", title: "Marital Intimacy in Sunni Islam", content: "Discover the guidance on marital intimacy in Sunni Islam and its role in strengthening bonds.", link: "/marital-intimacy-sunni-islam" },
        { imgSrc: "/assets/images/kindness_between_spouses.png", time: "December 8th, 2024", title: "Quran's Teaching on Kindness Between Spouses", content: "Learn how the Quran emphasizes kindness and its role in marriage between spouses.", link: "/quran-kindness-between-spouses" },
        { imgSrc: "/assets/images/sunni_couple_sharing_household_chores.png", time: "December 8th, 2024", title: "Household Chores in Sunni Marriage", content: "Explore how household chores are shared between spouses in Sunni Muslim marriages.", link: "/household-chores-in-sunni-marriage" },
        { imgSrc: "/assets/images/family_together_in_sunni_islam.png", time: "December 8th, 2024", title: "Family Unity in Sunni Islam", content: "Discover how to achieve unity and cohesion within a Sunni Muslim family.", link: "/family-unity-in-sunni-islam" },
        { imgSrc: "/assets/images/husband_responsibility_towards_wife.png", time: "December 8th, 2024", title: "Husband Responsibilities in Sunni Islam", content: "Learn about the husband’s key responsibilities towards his wife in Sunni Islam.", link: "/husband-responsibilities" },
        { imgSrc: "/assets/images/trust_in_sunni_marriage.png", time: "December 8th, 2024", title: "How to Nurture Trust in Islamic Marriage", content: "Explore how to build trust and sustain it in a Sunni Islamic marriage.", link: "/how-to-nurture-trust-in-islamic-marriage" },
        { imgSrc: "/assets/images/sunni_household_responsibilities.png", time: "December 8th, 2024", title: "How Are Responsibilities Divided in Sunni Household", content: "Learn how household responsibilities are divided and managed in Sunni families.", link: "/how-are-responsibilities-divided-in-sunni-household" },
        { imgSrc: "/assets/images/good_looking_muslim_couple_updated.png", time: "December 8th, 2024", title: "How Should Spouses Prioritize Their Faith in Marriage", content: "Discover how Muslim spouses can prioritize their faith to strengthen their marriage.", link: "/how-should-spouses-prioritize-their-faith-in-marriage" },
        { imgSrc: "/assets/images/fathers_role_in_raising_children.png", time: "December 8th, 2024", title: "Father's Role in Raising Children in Islam", content: "Learn how fathers play a pivotal role in raising children according to Islamic values.", link: "/fathers-role-in-raising-children-in-islam" },
        { imgSrc: "/assets/images/empathy_family_relationships.png", time: "December 8th, 2024", title: "How Can Empathy Improve Relationships Within Family", content: "Discover the role of empathy in improving family relationships in Islam.", link: "/how-can-empathy-improve-relationships-within-family" },
        { imgSrc: "/assets/images/charity_and_happiness_in_family.png", time: "December 8th, 2024", title: "Charity in Family Happiness", content: "Explore the role of charity in bringing happiness and barakah to Muslim families.", link: "/charity-in-family-happiness" },
        { imgSrc: "/assets/images/halal_family_holiday.png", time: "December 8th, 2024", title: "How Family Holidays Are Celebrated in Halal Way in Islam", content: "Learn how families can celebrate holidays while staying within halal Islamic practices.", link: "/how-family-holidays-are-celebrated-in-halal-way-in-islam" },
        { imgSrc: "/assets/images/honesty_between_parents_children.png", time: "December 8th, 2024", title: "Honesty Between Parents and Children", content: "Discover the significance of honesty between parents and children in a Muslim family.", link: "/honesty-between-parents-and-children" },
        { imgSrc: "/assets/images/family_heritage_muslim_couple.png", time: "December 8th, 2024", title: "Family Heritage in Islam", content: "Learn about preserving and honoring family heritage in a Muslim household.", link: "/family-heritage-in-islam" },
        { imgSrc: "/assets/images/sunni_marriage_harmony.png", time: "December 8th, 2024", title: "How Compromise Impacts Marital Harmony", content: "Discover how compromise plays a role in achieving marital harmony in Islam.", link: "/how-compromise-impacts-marital-harmony" },
        { imgSrc: "/assets/images/humility_in_sunni_family.png", time: "December 8th, 2024", title: "Role of Humility in Sunni Family Dynamics", content: "Explore how humility builds love and understanding in a Sunni family.", link: "/role-of-humility-in-sunni-family-dynamics" },
        { imgSrc: "/assets/images/how_conflicts_over_finances_in_islam.png", time: "December 8th, 2024", title: "How Should Conflicts Over Finances Be Resolved in Islam", content: "Learn Islamic guidelines for managing and resolving financial conflicts in marriage.", link: "/how-should-conflicts-over-finances-be-resolved-in-islam" },
        { imgSrc: "/assets/images/mother_role_spirituality_impact.png", time: "December 8th, 2024", title: "Mother's Role Impacting Family Spirituality", content: "Discover how a mother’s role influences the spiritual growth of a Muslim family.", link: "/mothers-role-impact-family-spirituality" },
        { imgSrc: "/assets/images/respectful-muslim-husband-in-laws.png", time: "December 8th, 2024", title: "Husband's Duties Towards In-Laws", content: "Explore how a husband fulfills his obligations and duties towards his in-laws.", link: "/husband-duties-towards-in-laws" },
        { imgSrc: "/assets/images/sunni_marriage_mutual_decision.png", time: "December 8th, 2024", title: "How Mutual Decision-Making is Encouraged in Sunni Marriage", content: "Learn how mutual decision-making strengthens marriage in Sunni Islam.", link: "/how-mutual-decision-making-is-encouraged-in-sunni-marriage" },
        { imgSrc: "/assets/images/muslim_family_gratitude.png", time: "December 8th, 2024", title: "How Families Nurture Gratitude", content: "Explore how Muslim families can foster gratitude in everyday life.", link: "/how-families-nurture-gratitude" },
        { imgSrc: "/assets/images/family_generosity_practice.png", time: "December 8th, 2024", title: "How Can a Family Practice Generosity in Everyday Life", content: "Discover how generosity can be part of daily family interactions in Islam.", link: "/how-can-a-family-practice-generosity-in-everyday-life" },
        { imgSrc: "/assets/images/community_support_in_sunni_marriages.png", time: "December 8th, 2024", title: "Community Support in Sunni Marriages", content: "Learn about the role of community support in enhancing Sunni marriages.", link: "/community-support-in-sunni-marriages" },
        { imgSrc: "/assets/images/muslim_family_gathering_image.png", time: "December 8th, 2024", title: "Family Gatherings in Islam", content: "Discover how family gatherings are celebrated and cherished within Islamic teachings.", link: "/family-gatherings-in-islam" },
        { imgSrc: "/assets/images/family_balance_work_responsibilities.png", time: "December 8th, 2024", title: "How Family Responsibilities Should Be Balanced With Work Obligations", content: "Learn how Muslim families can balance work obligations with family responsibilities.", link: "/how-family-responsibilities-should-be-balanced-with-work-obligations" },
        { imgSrc: "/assets/images/respect_wifes_aspirations.png", time: "December 8th, 2024", title: "Respect Wife's Aspirations", content: "Explore how respecting the aspirations of a wife strengthens the family bond in Islam.", link: "/respect-wifes-aspirations" }
    ];
    
    
                    

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog9" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10" className="active">10</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
