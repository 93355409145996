import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getMatchMaker } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { confirmSignUp, signOut } from "aws-amplify/auth";
import { updateMatchMaker } from "../../../graphql/mutations";
import "@aws-amplify/ui-react/styles.css";
import BGImg from "../../../assets/images/bread_crumb_bg.png";
import { Helmet } from "react-helmet";

const client = generateClient();

const MatchMakerHome = () => {
  const [matchMaker, setMatchMaker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationError, setVerificationError] = useState(null);
  const { matchMakerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatchMaker = async () => {
      try {
        const matchMakerData = await client.graphql({
          query: getMatchMaker,
          variables: { id: matchMakerId },
        });

        if (matchMakerData.data) {
          setMatchMaker(matchMakerData.data.getMatchMaker);
          setLoading(false);
        } else {
          setError("Error loading matchmaker data");
          setLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchMatchMaker();
  }, [matchMakerId]);

  const handleVerification = async (e) => {
    e.preventDefault();
    if (!verificationCode) {
      setVerificationError("Verification code is required");
      return;
    }

    try {
      await confirmSignUp({
        username: matchMaker.username,
        confirmationCode: verificationCode,
      });

      const result = await client.graphql({
        query: updateMatchMaker,
        variables: {
          input: { id: matchMaker.id, verified: true },
        },
      });

      if (result.data) {
        setMatchMaker({ ...matchMaker, verified: true });
        navigate(`/matchmaker-home/${matchMakerId}`);
      } else {
        setVerificationError("Error updating verification status");
      }
    } catch (error) {
      setVerificationError(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>MatchMaker Home - Manage Candidates</title>
        <meta
          name="description"
          content="Manage candidates, verify your account, and create or view candidate profiles in the MatchMaker dashboard."
        />
      </Helmet>

      {/* Breadcrumb Section */}
      <div
        className="bred_crumb"
        style={{
          backgroundImage: `url(${BGImg})`,
          padding: "50px 0",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <div className="container">
          <div className="bred_text">
            <h1>MatchMaker Dashboard</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>»</span>
              </li>
              <li>Dashboard</li>
            </ul>
          </div>
        </div> */}
      </div>

      {/* Main Section */}
      <section className="matchmaker-home">
        <div className="container">
            <div
            className="dashboard-card"
            style={{
                background: "#fff",
                padding: "40px",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                maxWidth: "600px",
                margin: "0 auto 50px", // Added margin-bottom for spacing
            }}
            >
            {matchMaker.avatar && (
                <div className="avatar-section" style={{ marginBottom: "20px" }}>
                <img
                    src={matchMaker.avatar}
                    alt="MatchMaker Avatar"
                    style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                    border: "4px solid #007bff",
                    objectFit: "cover",
                    }}
                />
                </div>
            )}
            <h2
                className="dashboard-title"
                style={{
                fontSize: "28px",
                fontWeight: "700",
                margin: "20px 0 10px",
                color: "#333",
                }}
            >
                Welcome, {matchMaker.bureauName || "MatchMaker"}
            </h2>
            <p
                className="dashboard-description"
                style={{
                fontSize: "16px",
                color: "#666",
                marginBottom: "30px",
                }}
            >
                Manage your candidates efficiently with our tools and dashboard.
            </p>
            {matchMaker.verified ? (
                <div
                className="dashboard-actions"
                style={{ display: "flex", justifyContent: "center", gap: "15px" }}
                >
                <Link
                    to={`/matchmaker-home/${matchMakerId}/create-candidate`}
                    style={{
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    borderRadius: "6px",
                    textDecoration: "none",
                    }}
                >
                    Create Candidate
                </Link>
                <Link
                    to={`/matchmaker-home/${matchMakerId}/view-candidates`}
                    style={{
                    padding: "10px 20px",
                    backgroundColor: "#6c757d",
                    color: "#fff",
                    borderRadius: "6px",
                    textDecoration: "none",
                    }}
                >
                    View Candidates
                </Link>
                <button
                    onClick={handleSignOut}
                    style={{
                    padding: "10px 20px",
                    backgroundColor: "#dc3545",
                    color: "#fff",
                    border: "none",
                    borderRadius: "6px",
                    cursor: "pointer",
                    }}
                >
                    Sign Out
                </button>
                </div>
            ) : (
                <div
                className="verification-section"
                style={{ marginTop: "20px" }}
                >
                <form onSubmit={handleVerification}>
                    <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label>Verification Code*</label>
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                        width: "100%",
                        fontSize: "14px",
                        }}
                        placeholder="Enter Verification Code"
                    />
                    </div>
                    <button
                    type="submit"
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        borderRadius: "6px",
                        border: "none",
                        cursor: "pointer",
                    }}
                    >
                    Verify Account
                    </button>
                    {verificationError && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                        {verificationError}
                    </p>
                    )}
                </form>
                </div>
            )}
            </div>
        </div>
        </section>

    </>
  );
};

export default MatchMakerHome;
