import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signIn, getCurrentUser } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsExports from '../../../aws-exports'; // Adjust the path as needed
import '@aws-amplify/ui-react/styles.css';
import { listMatchMakers } from '../../../graphql/queries';

Amplify.configure(awsExports);
const client = generateClient();

const Main = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const username = form.elements.username.value;
        const password = form.elements.password.value;

        if (!username) {
            setErrorMessage("Email is required");
            return;
        }

        if (!password) {
            setErrorMessage("Password is required");
            return;
        }

        try {
            await signIn({
                username: username,
                password: password,
                options: {
                    authFlowType: 'USER_PASSWORD_AUTH',
                },
            });

            console.log("Sign-in successful");

            const currentUser = await getCurrentUser();
            const userId = currentUser.userId;

            if (userId) {
                console.log("User ID:", userId);

                const matchMakersData = await client.graphql({
                    query: listMatchMakers,
                });

                const matchMaker = matchMakersData.data.listMatchMakers.items.find(mm => mm.username === username);

                if (matchMaker) {
                    console.log("Matchmaker found:", matchMaker);
                    navigate(`/matchmaker-home/${matchMaker.id}`);
                } else {
                    setErrorMessage("MatchMaker data not found");
                }
            } else {
                setErrorMessage("User ID not found");
            }
        } catch (error) {
            console.log("Error during sign-in:", error);
            setErrorMessage(error.message);
        }
    };

    return (
        <>
            <div className="page_wrapper">
                <div className="full_bg">
                    <div className="container">
                        <section className="signup_section">
                            <div className="top_part">
                                <Link to="/" className="back_btn">
                                    <i className="icofont-arrow-left"></i> Back
                                </Link>
                                {/* <Link to="/" className="navbar-brand">
                                    <img src={FooterLogo} alt="MiSoulmate" />
                                </Link> */}
                            </div>
                            <div className="signup_form">
                                <div className="section_title">
                                    <h2>
                                        Welcome to <span>MiSoulmate</span>
                                    </h2>
                                    <p>
                                        Log in to access the Match Maker dashboard and manage matches efficiently.
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="username"
                                            name="username"
                                            className="form-control"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Password"
                                        />
                                    </div>
                                    {errorMessage && (
                                        <p style={{ color: 'red' }}>{errorMessage}</p>
                                    )}
                                    <div className="form-group">
                                        <button className="btn puprple_btn" type="submit">
                                            SIGN IN
                                        </button>
                                    </div>
                                </form>
                                {/* <div className="or_option">
                                    <p>Don't have an account? <Link to="/sign-up">Sign Up here</Link></p>
                                </div> */}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Main;
