import { BrowserRouter as Router } from "react-router-dom";
import Routing from './routes';
import './assets/css/icofont.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';
import './assets/css/style.css';

export default function App() {
  return (
    <>
      <Router
        basename="/"
        future={{
          v7_startTransition: true, // Enable React's startTransition for Router state updates
          v7_relativeSplatPath: true, // Handle multi-segment splat paths correctly
          // Add other flags if needed:
          // v7_fetcherPersist: true,
          // v7_normalizeFormMethod: true,
          // v7_partialHydration: true,
          // v7_skipActionErrorRevalidation: true,
        }}
      >
        <Routing />
      </Router>
    </>
  );
}
