import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { createMatchMaker } from "../../../graphql/mutations";
import { Amplify } from "aws-amplify";
import { signUp } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import awsExports from "../../../aws-exports";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsExports);
const client = generateClient();

class MatchMakerSignUp extends Component {
    state = {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        bureauName: "",
        clientCount: 0,
        errorMessage: "",
        fieldErrors: {},
        redirectToHome: false,
        matchMakerId: "",
    };

    validateForm() {
        const { username, email, password, confirmPassword, bureauName, clientCount } = this.state;
        const fieldErrors = {};

        if (!username) fieldErrors.username = "Username is required.";
        if (!email) fieldErrors.email = "Email is required.";
        if (!password) fieldErrors.password = "Password is required.";
        if (!confirmPassword) fieldErrors.confirmPassword = "Confirm Password is required.";
        if (!bureauName) fieldErrors.bureauName = "Bureau Name is required.";
        if (clientCount <= 0) fieldErrors.clientCount = "Client count must be greater than zero.";

        if (password !== confirmPassword) fieldErrors.confirmPassword = "Passwords do not match.";

        this.setState({ fieldErrors });

        return Object.keys(fieldErrors).length === 0;
    }

    async handleSubmit(event) {
        event.preventDefault();
        if (!this.validateForm()) return;

        try {
            const { username, email, password } = this.state;
            const { userSub } = await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email,
                    },
                },
            });

            const result = await client.graphql({
                query: createMatchMaker,
                variables: {
                    input: {
                        username,
                        email,
                        password,
                        bureauName: this.state.bureauName,
                        clientCount: this.state.clientCount,
                        verified: false,
                    },
                },
            });

            const matchMakerId = result.data.createMatchMaker.id;
            this.setState({ redirectToHome: true, matchMakerId });
            localStorage.setItem("matchMakerUsername", username);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    render() {
        if (this.state.redirectToHome) {
            return <Navigate to={`/matchmaker-home/${this.state.matchMakerId}`} />;
        }

        const { fieldErrors, errorMessage } = this.state;

        return (
            <div className="page_wrapper">
                <div className="full_bg">
                    <div className="container">
                        <section className="signup_section">
                            <div className="top_part">
                                <Link to="/" className="back_btn">
                                    <i className="icofont-arrow-left"></i> Back
                                </Link>
                                {/* <Link to="/" className="navbar-brand">
                                    <img src={FooterLogo} alt="MiSoulmate" />
                                </Link> */}
                            </div>
                            <div className="signup_form">
                                <div className="section_title">
                                    <h2>
                                        <span>Create</span> an account
                                    </h2>
                                    <p>
                                        Fill all fields so we can get some info about you.
                                        <br />
                                        We'll never send you spam.
                                    </p>
                                </div>
                                <form onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Username"
                                            value={this.state.username}
                                            onChange={(e) => this.setState({ username: e.target.value })}
                                        />
                                        {fieldErrors.username && <p className="error-message">{fieldErrors.username}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                        {fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={(e) => this.setState({ password: e.target.value })}
                                        />
                                        {fieldErrors.password && <p className="error-message">{fieldErrors.password}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            value={this.state.confirmPassword}
                                            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                        />
                                        {fieldErrors.confirmPassword && (
                                            <p className="error-message">{fieldErrors.confirmPassword}</p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Bureau Name"
                                            value={this.state.bureauName}
                                            onChange={(e) => this.setState({ bureauName: e.target.value })}
                                        />
                                        {fieldErrors.bureauName && <p className="error-message">{fieldErrors.bureauName}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Number of Clients"
                                            value={this.state.clientCount}
                                            onChange={(e) => this.setState({ clientCount: parseInt(e.target.value) })}
                                        />
                                        {fieldErrors.clientCount && <p className="error-message">{fieldErrors.clientCount}</p>}
                                    </div>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    <div className="form-group">
                                        <button className="btn puprple_btn" type="submit">
                                            SIGN UP
                                        </button>
                                    </div>
                                </form>
                                <div className="or_option">
                                    <p>
                                        Already have an account? <Link to="/sign-in">Sign In here</Link>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default MatchMakerSignUp;
