import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { listMessages } from "../../../graphql/queries";
import { createMessage, updateMessage } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import BGImg from "../../../assets/images/bread_crumb_bg.png";

const client = generateClient();

const MessagesView = () => {
    const { matchId } = useParams();
    const { matchMakerId } = useParams();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [username, setUsername] = useState("YourName");

    // Fetch all messages from the server and mark unread messages as read
    const fetchMessages = async () => {
        try {
            console.log(`Fetching messages for matchId: ${matchId}`);

            const messagesData = await client.graphql({
                query: listMessages,
                variables: {
                    filter: {
                        matchID: { eq: matchId },
                    },
                },
            });

            if (messagesData.data?.listMessages?.items) {
                const sortedMessages = messagesData.data.listMessages.items.sort(
                    (a, b) => a.creationDate - b.creationDate
                );

                setMessages(sortedMessages);

                // Find unread messages and mark them as read
                const unreadMessages = sortedMessages.filter((msg) => !msg.read);
                for (const message of unreadMessages) {
                    await markMessageAsRead(message.id);
                }
            } else {
                setMessages([]);
            }
            setLoading(false);
        } catch (err) {
            console.error("Error fetching messages:", err);
            setError(err.message);
            setLoading(false);
        }
    };

    // Function to mark a message as read
    const markMessageAsRead = async (messageId) => {
        try {
            await client.graphql({
                query: updateMessage,
                variables: {
                    input: {
                        id: messageId,
                        read: true,
                        readDate: Math.floor(Date.now() / 1000),
                    },
                },
            });
            console.log(`Message ${messageId} marked as read.`);
        } catch (err) {
            console.error("Error updating message status:", err);
        }
    };

    // Fetch the username of the current user
    const fetchUser = async () => {
        try {
            const user = await getCurrentUser();
            setUsername(user.username || "YourName");
        } catch (err) {
            console.error("Error fetching user:", err);
        }
    };

    useEffect(() => {
        fetchMessages();
        fetchUser();

        // Poll every 5 seconds
        const intervalId = setInterval(fetchMessages, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [matchId]);

    // Handle sending a new message
    const handleSendMessage = async () => {
        try {
            if (newMessage.trim() === "") return;

            console.log("Sending message with matchID:", matchId);

            const messageDetails = {
                matchID: matchId,
                senderName: username,
                body: newMessage,
                creationDate: Math.floor(Date.now() / 1000),
                deviceToken: "",
                read: false,
                readDate: null,
                chatroomCity: "",
                userStatus: "",
            };

            const result = await client.graphql({
                query: createMessage,
                variables: { input: messageDetails },
            });

            console.log("Message sent:", result);

            if (result.data?.createMessage) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    result.data.createMessage,
                ]);
            }
            setNewMessage("");
        } catch (err) {
            console.error("Error sending message:", err);
            setError(err.message);
        }
    };

    if (loading) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#555" }}>Loading Messages...</p>
            </>
        );
    }

    if (error) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "red" }}>Error loading candidates: {error}</p>
            </>
        );
    }

    return (
        <Fragment>
            {/* Breadcrumb Section */}
            <div
                className="bred_crumb"
                style={{
                    backgroundImage: `url(${BGImg})`,
                    padding: "50px 0",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
            <div style={{ textAlign: "left", margin: "40px 0 20px 360px" }}>
                <button
                    onClick={() => window.history.back()}
                    style={{ color: "#007bff", fontSize: "16px", textDecoration: "none", background: "none", border: "none", cursor: "pointer" }}
                >
                    &larr; Back to View Candidates
                </button>
            </div>
            <div className="messages-view" style={styles.container}>
                <h2 style={styles.title}>Messages</h2>
                <div style={styles.messagesList}>
                    {messages.map((message) => (
                        <div key={message.id} style={styles.messageCard}>
                            <p>
                                <strong>{message.senderName}</strong>:{" "}
                                {message.body}
                            </p>
                            <p style={styles.messageDate}>
                                {new Date(
                                    message.creationDate * 1000
                                ).toLocaleString()}
                            </p>
                        </div>
                    ))}
                </div>
                <div style={styles.messageInput}>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        style={styles.input}
                    />
                    <button
                        onClick={handleSendMessage}
                        style={styles.button}
                    >
                        Send
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    container: {
        padding: "40px",
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "left",
        backgroundColor: "#f7f7f9",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    title: {
        textAlign: "center",
        color: "#333",
        marginBottom: "20px",
    },
    messagesList: {
        maxHeight: "400px",
        overflowY: "scroll",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
    },
    messageCard: {
        backgroundColor: "#f1f1f1",
        borderRadius: "8px",
        padding: "15px",
        margin: "10px 0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    messageDate: {
        fontSize: "0.8rem",
        color: "#999",
        marginTop: "5px",
    },
    messageInput: {
        display: "flex",
        marginTop: "20px",
        alignItems: "center",
    },
    input: {
        flex: 1,
        padding: "15px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        marginRight: "10px",
        fontSize: "1rem",
    },
    button: {
        padding: "15px 20px",
        borderRadius: "5px",
        border: "none",
        backgroundColor: "#007bff",
        color: "#fff",
        cursor: "pointer",
        fontSize: "1rem",
    },
};

export default MessagesView;
