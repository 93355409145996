import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/rights_responsibilities_spouses_sunni_islam.png";

function RightsAndResponsibilitiesOfSpouses() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Rights and Responsibilities of Spouses in Sunni Islam | Islamic Insights</title>
                <meta name="description" content="Learn about the rights and responsibilities of spouses in Sunni Islam, and how mutual respect and fairness are emphasized in marriage." />
                <meta name="keywords" content="Rights and Responsibilities of Spouses, Sunni Islam Marriage, Islamic Marriage, Marriage in Islam, Family in Islam, Muslim Marriage" />
                <meta property="og:title" content="Rights and Responsibilities of Spouses in Sunni Islam | Islamic Insights" />
                <meta property="og:description" content="Explore the rights and duties of both husbands and wives in Sunni Islam and the importance of mutual respect, support, and fairness in the marital relationship." />
                <meta property="og:image" content="/path/to/share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/rights-and-responsibilities-of-spouses-in-sunni-islam" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="Rights and Responsibilities of Spouses in Sunni Islam" 
                paragraph="Discover the mutual rights and duties of spouses in Sunni Islam, as well as the principles of love, respect, and fairness in marriage." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 8, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>Rights and Responsibilities of Spouses in Sunni Islam</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Rights and Responsibilities of Spouses in Sunni Islam" />
                        </div>
                        <div className="info">
                            <p>In Sunni Islam, marriage is a sacred institution that binds a man and a woman in a lifelong commitment. It is not only a legal contract but also a bond built on mutual respect, love, and responsibility. Both husbands and wives have defined rights and duties, which serve to maintain balance, fairness, and harmony in their relationship. The Quran and Hadith provide clear guidance on these rights and responsibilities, ensuring a strong foundation for a peaceful family life.</p>

                            <p>The rights of spouses in Sunni Islam are based on the principles of justice, kindness, and equality. Both partners are entitled to respect, emotional support, and physical care. The Prophet Muhammad (PBUH) said, "The best of you are those who are the best to their wives" (Tirmidhi), emphasizing the importance of kindness and compassion in the marital relationship.</p>

                            <h3>Rights of the Wife in Sunni Islam</h3>
                            <p>The wife has several important rights in Sunni Islam. These include:</p>
                            <ul>
                                <li>Right to financial support: The husband is responsible for providing for his wife’s living expenses, including food, clothing, and shelter.</li>
                                <li>Right to kindness and respect: The husband must treat his wife with kindness, compassion, and respect. She should never be mistreated or oppressed.</li>
                                <li>Right to companionship and emotional support: The wife has the right to be emotionally supported and treated as an equal partner in the marriage.</li>
                                <li>Right to maintenance during Iddah: If the marriage ends through divorce or the husband’s death, the wife has the right to financial support during the waiting period (iddah), as per Islamic law.</li>
                            </ul>

                            <h3>Rights of the Husband in Sunni Islam</h3>
                            <p>Similarly, the husband has his own rights in the marriage, which include:</p>
                            <ul>
                                <li>Right to respect and loyalty: The wife is expected to treat her husband with respect, loyalty, and gratitude for his efforts in maintaining the family.</li>
                                <li>Right to sexual intimacy: In Islam, marriage is seen as a lawful way to fulfill natural desires, and the wife is obliged to maintain intimacy with her husband, as long as it is done with mutual consent and respect.</li>
                                <li>Right to be consulted: The husband should be consulted by his wife on important family matters, creating an environment of mutual decision-making.</li>
                            </ul>

                            <h3>Shared Responsibilities in Sunni Marriage</h3>
                            <p>In Sunni Islam, both spouses share the responsibility of maintaining the family unit. This includes:</p>
                            <ul>
                                <li>Providing a safe and loving home: Both the husband and wife are responsible for creating a nurturing environment for their children, where they can grow up with proper guidance and care.</li>
                                <li>Mutual consultation and decision-making: Both partners are encouraged to consult each other in important matters, maintaining a spirit of cooperation and understanding.</li>
                                <li>Raising children with Islamic values: Both spouses share the responsibility of teaching their children Islamic principles, ensuring they grow up to be righteous individuals.</li>
                            </ul>

                            <h3>The Role of Patience and Forgiveness</h3>
                            <p>Marriage in Sunni Islam emphasizes patience, forgiveness, and understanding. As human beings, both spouses may have shortcomings, and it is through mutual forgiveness that the marriage can endure. The Prophet Muhammad (PBUH) advised, "The strong person is not the one who is able to overpower others, but the one who is able to control himself when he is angry" (Sahih Bukhari). This highlights the importance of self-control and forgiveness in maintaining a harmonious marriage.</p>

                            <h3>Mutual Rights and Equality</h3>
                            <p>In Sunni Islam, the rights and responsibilities of husbands and wives are viewed as complementary. While both have different roles, they are equal partners in the marriage. Islam encourages mutual respect, love, and sacrifice, where each partner supports the other in fulfilling their individual and collective duties. The Quran says, "And they (wives) have rights similar to those (husbands) over them in kindness" (Quran 2:228), stressing the importance of fairness and equality.</p>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"The best of you are those who are the best to their wives." – Prophet Muhammad (PBUH)</h2>
                            </div>

                            <p>In conclusion, marriage in Sunni Islam is based on the principles of equality, kindness, and mutual respect. Both spouses are entrusted with the responsibility of maintaining a healthy and loving relationship. By upholding each other's rights and fulfilling their responsibilities, Muslim couples can create a stable family life and strengthen their faith. Islam provides a clear framework for marriage, ensuring that both partners are treated with dignity and fairness.</p>

                        </div>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 8, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Sunni Islam,</span></li>
                                <li><span>Marriage,</span></li>
                                <li><span>Rights and Responsibilities,</span></li>
                                <li><span>Family in Islam</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default  RightsAndResponsibilitiesOfSpouses;
