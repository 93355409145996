import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { listUsers, listMatchMakers, listLikes, listMessages, getUser, listMatches } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";

const client = generateClient();

const ViewCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [inquiringUsersCount, setInquiringUsersCount] = useState({});
  const [unreadMessagesMap, setUnreadMessagesMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [matchMakerId, setMatchMakerId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { username } = await getCurrentUser();
        const matchMakersData = await client.graphql({ query: listMatchMakers });

        const foundMatchMaker = matchMakersData.data.listMatchMakers.items.find(
          (mm) => mm.username === username
        );

        if (foundMatchMaker) {
          setMatchMakerId(foundMatchMaker.id);

          const result = await client.graphql({
            query: listUsers,
            variables: {
              filter: { matchmakerID: { eq: foundMatchMaker.id } },
            },
          });

          const candidateList = result.data.listUsers.items;
          setCandidates(candidateList);

          // Fetch likes count for each candidate
          const likesPromises = candidateList.map(async (candidate) => {
            const likesResult = await client.graphql({
              query: listLikes,
              variables: {
                filter: {
                  disliked_user_id: { eq: "MatchMaker" },
                  liked_user_id: { eq: candidate.id },
                },
                limit: 1000,
              },
            });

            return { candidateId: candidate.id, count: likesResult.data.listLikes.items.length };
          });

          const likesData = await Promise.all(likesPromises);
          const likesCountMap = likesData.reduce((acc, item) => {
            acc[item.candidateId] = item.count;
            return acc;
          }, {});

          setInquiringUsersCount(likesCountMap);

          // Check for unread messages for each candidate
          candidateList.forEach(async (candidate) => {
            await fetchUnreadMessages(candidate.id);
          });
        } else {
          setError("Matchmaker not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchUnreadMessages = async (candidateId) => {
      try {
        let allLikes = [];
        let nextToken = null;

        do {
          const likesResult = await client.graphql({
            query: listLikes,
            variables: {
              filter: {
                disliked_user_id: { eq: "MatchMaker" },
                liked_user_id: { eq: candidateId },
              },
              nextToken,
            },
          });

          const items = likesResult.data.listLikes.items || [];
          allLikes = [...allLikes, ...items];
          nextToken = likesResult.data.listLikes.nextToken;
        } while (nextToken);

        if (allLikes.length === 0) {
          setUnreadMessagesMap((prev) => ({ ...prev, [candidateId]: false }));
          return;
        }

        let hasUnreadMessages = false;

        for (const like of allLikes) {
          const matchResult = await client.graphql({
            query: listMatches,
            variables: {
              filter: {
                userID: { eq: like.userID },
                liked_user_id: { eq: candidateId },
              },
            },
          });

          const matchId = matchResult.data.listMatches.items.length > 0
            ? matchResult.data.listMatches.items[0].match_id
            : null;

          if (matchId) {
            const messagesResult = await client.graphql({
              query: listMessages,
              variables: {
                filter: {
                  matchID: { eq: matchId },
                  read: { eq: false },
                },
              },
            });

            if (messagesResult.data.listMessages.items.length > 0) {
              hasUnreadMessages = true;
              break;
            }
          }
        }

        setUnreadMessagesMap((prev) => ({ ...prev, [candidateId]: hasUnreadMessages }));
      } catch (error) {
        console.error("Error fetching unread messages:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (candidateId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this candidate? This action cannot be undone."
    );
    if (!confirmDelete) return;

    try {
      const response = await client.graphql({
        query: `
          mutation DeleteUser($id: ID!) {
            deleteUser(input: { id: $id }) {
              id
            }
          }
        `,
        variables: { id: candidateId },
      });

      if (response?.data?.deleteUser?.id) {
        alert("Candidate deleted successfully.");
        setCandidates((prev) => prev.filter((c) => c.id !== candidateId));
      } else {
        alert("Failed to delete candidate.");
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
      alert("An error occurred while deleting the candidate.");
    }
  };


  if (loading) {
    return (
      <>
        <div className="bred_crumb_wrapper">
          <div
            className="bred_crumb"
            style={{
              backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
              padding: "50px 0",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#555" }}>Loading All Candidates...</p>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="bred_crumb_wrapper">
          <div
            className="bred_crumb"
            style={{
              backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
              padding: "50px 0",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <p style={{ textAlign: "center", fontSize: "1.2rem", color: "red" }}>Error loading candidates: {error}</p>
      </>
    );
  }

  return (
    <>
      <div className="bred_crumb_wrapper">
        <div
          className="bred_crumb"
          style={{
            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
            padding: "50px 0",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>

      <div style={{ textAlign: "left", margin: "40px 0 20px 360px" }}>
        <Link
          to={`/matchmaker-home/${matchMakerId}`}
          style={{ color: "#007bff", fontSize: "16px", textDecoration: "none" }}
        >
          &larr; Back to Matchmaker Home
        </Link>
      </div>

      <div className="view-candidates" style={styles.container}>
        <h2 style={styles.title}>My Candidates</h2>
        <div className="candidate-list" style={styles.list}>
          {candidates.map((candidate) => (
            <div key={candidate.id} className="candidate-card" style={styles.card}>
              <div style={styles.imageContainer}>
                <img
                  src={candidate.avatar || "/assets/images/placeholder.png"}
                  alt={candidate.name}
                  style={styles.image}
                />
                {inquiringUsersCount[candidate.id] > 0 && (
                  <div style={styles.inquiringUsersBadge}>
                    {inquiringUsersCount[candidate.id]}
                  </div>
                )}
                {unreadMessagesMap[candidate.id] && (
                  <div style={styles.unreadBadge}></div>
                )}
              </div>
              <h3 style={styles.name}>{candidate.name}, {candidate.age}</h3>
              <p style={styles.bio}>{candidate.city}, {candidate.country}</p>

              <div style={styles.actions}>

                <Link
                  to={`/matchmaker-home/${matchMakerId}/view-candidate/${candidate.id}`}
                  state={{ matchMakerId, candidate }}
                  className="default-btn reverse"
                  style={styles.button}
                >
                  <span>View Profile</span>
                </Link>

                <button
                  style={styles.deleteButton}
                  onClick={() => handleDelete(candidate.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};


// Styles
const styles = {
  unreadBadge: { position: "absolute", top: "5px", left: "5px", background: "red", borderRadius: "50%", width: "20px", height: "20px" },
  container: {
    padding: "40px",
    maxWidth: "1200px",
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "#f7f7f9",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
  card: {
    background: "#fff",
    padding: "25px",
    borderRadius: "15px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
    width: "280px",
    position: "relative",
    textAlign: "center",
    transition: "transform 0.3s ease-in-out",
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  image: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    border: "3px solid #007bff",
  },
  inquiringUsersBadge: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "#007bff",
    color: "#fff",
    borderRadius: "50%",
    padding: "6px 12px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  name: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#333",
    marginTop: "15px",
  },
  bio: {
    fontSize: "16px",
    color: "#555",
    marginTop: "10px",
    marginBottom: "15px",
  },
  actions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "12px 24px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
  },
  deleteButton: {
    backgroundColor: "#ff4d4d",
    color: "#fff",
    border: "none",
    padding: "12px 24px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
  },
};


export default ViewCandidates;
