import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/good_looking_muslim_couple.png";

function RightsOfWifeInSunniIslam() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Rights of a Wife in Sunni Islam | Islamic Insights</title>
                <meta name="description" content="Explore the rights of a wife in Sunni Islam, including her rights to love, support, financial security, and respect within the marriage." />
                <meta name="keywords" content="Wife's Rights in Sunni Islam, Islamic Marriage, Women's Rights in Islam, Marriage in Islam, Family Rights, Islam and Women" />
                <meta property="og:title" content="Rights of a Wife in Sunni Islam | Islamic Insights" />
                <meta property="og:description" content="Discover the various rights of a wife in Sunni Islam, from financial protection to mutual respect, and how these rights contribute to a strong and harmonious marriage." />
                <meta property="og:image" content="/path/to/share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/rights-of-a-wife-in-sunni-islam" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="Rights of a Wife in Sunni Islam" 
                paragraph="Learn about the spiritual, financial, and emotional rights of a wife in a Sunni Muslim marriage." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 8, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>Rights of a Wife in Sunni Islam</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Rights of a Wife in Sunni Islam" />
                        </div>
                        <div className="info">
                            <p>In Sunni Islam, the rights of a wife are considered an integral part of a healthy and balanced marriage. These rights are outlined in the Quran and Hadith, and they emphasize the importance of love, respect, and fairness in the relationship between spouses. A wife in Islam is granted various rights, both spiritual and material, which help ensure her dignity, security, and happiness within the marriage.</p>

                            <h3>1. Right to Financial Security</h3>
                            <p>One of the primary rights of a wife in Sunni Islam is financial support. The husband is responsible for providing his wife with maintenance (known as *nafaqah*), which includes food, clothing, shelter, and any other needs she may have. This responsibility is based on the Quranic verse: "Let the rich man spend according to his means, and the poor man according to what Allah has given him" (Quran 65:7). It is crucial for a husband to provide for his wife with generosity and kindness.</p>

                            <h3>2. Right to Love and Respect</h3>
                            <p>Respect and affection are essential components of a marital relationship in Islam. A wife has the right to be treated with love, care, and dignity. The Quran instructs men to treat their wives with kindness: "And live with them in kindness" (Quran 4:19). This encompasses emotional, physical, and intellectual support, ensuring that the wife feels valued and cherished in the relationship.</p>

                            <h3>3. Right to a Just and Fair Marriage</h3>
                            <p>Islam places great emphasis on justice and fairness in marriage. A wife has the right to be treated equally and fairly by her husband, without any form of oppression or harm. If a husband has more than one wife, he is required to treat them equally in terms of financial support, time, and affection. The Quran stresses: "Then if you fear that you will not be just, then marry those that please you of [other] women, two or three or four. But if you fear that you will not be just, then [marry only] one" (Quran 4:3).</p>

                            <h3>4. Right to Privacy and Protection</h3>
                            <p>A wife in Islam has the right to privacy and protection, both physically and emotionally. This includes the right to her personal space and the assurance that her husband will not violate her boundaries. The marriage relationship is meant to be a sanctuary where both partners feel safe and secure. The Prophet Muhammad (PBUH) emphasized this by saying, "The best of you are those who are the best to their wives" (Sunan Ibn Majah).</p>

                            <h3>5. Right to Be Heard and Included in Decisions</h3>
                            <p>In Sunni Islam, marriage is based on mutual consultation and respect. A wife has the right to have her voice heard in family decisions and to participate in discussions that affect her well-being and that of the family. The Prophet Muhammad (PBUH) sought the opinion of his wives in matters of governance, which shows the importance of mutual decision-making in a marriage.</p>

                            <h3>6. Right to Divorce (Khula)</h3>
                            <p>While a husband has the right to initiate divorce (Talaq), a wife also has the right to seek divorce through a process known as *khula*. This is granted when a wife feels that she is unable to live in the marriage with peace and harmony. She can approach an Islamic court to seek a divorce, which is a right that Islam gives to protect her from harm and ensure her happiness and safety in the marriage.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> The husband must provide for his wife financially, ensuring her security and comfort.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> A wife is entitled to love, respect, and kindness from her husband.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Marriage should be based on justice and fairness, ensuring equal treatment for all wives.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> The wife has the right to privacy, protection, and emotional security.</p></li>
                            </ul>
                        </div>

                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                            <h2>"The best of you are those who are the best to their wives." </h2>
                            <p><span className="name">Prophet Muhammad (PBUH),</span> Sunan Ibn Majah</p>
                        </div>

                        <p>In conclusion, the rights of a wife in Sunni Islam ensure that marriage is not only a legal contract but also a relationship based on respect, love, and mutual support. These rights aim to create a balanced and healthy marriage, where both partners fulfill their roles and contribute to each other's well-being. By adhering to these rights, a Muslim marriage can thrive and be a source of tranquility, security, and happiness.</p>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 8, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Wife's Rights,</span></li>
                                <li><span>Sunni Islam,</span></li>
                                <li><span>Marriage,</span></li>
                                <li><span>Islamic Marriage</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default  RightsOfWifeInSunniIslam;
