import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import logo1 from "../../assets/images/logo.png";
import { generateClient } from 'aws-amplify/api';
import { listMatchMakers } from '../../graphql/queries';

const client = generateClient();

const Header = ({ navbar }) => {
  const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [matchMakerId, setMatchMakerId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          setUsername(user.username);

          // Simulate fetching matchmaker data
          const matchMakersData = await client.graphql({
            query: listMatchMakers,
          });
          const matchMaker = matchMakersData.data.listMatchMakers.items.find(
            (mm) => mm.username === user.username
          );
          if (matchMaker) {
            setIsAuthenticated(true);
            setMatchMakerId(matchMaker.id);
          }
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
        setIsAuthenticated(false);
        setUsername('');
      }
    };

    fetchCurrentUser();
  }, []);

  const toggleMobileMenu = () => {
    setMobile(!mobile);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      setUsername('');
      navigate('/'); // Navigate to the home or login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className={`header ${scrolled ? 'fix_style fixed' : ''}`}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link to="/" className="navbar-brand">
            <img src={logo1} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
          >
            <div className={`toggle-wrap ${mobile && "active"}`}>
              <span className="toggle-bar"></span>
            </div>
          </button>

          <div className={`collapse navbar-collapse ${mobile ? "show" : ""}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/misoulmate-muslim-marriage/id6596806129?itscg=30200&itsct=apps_box_link&mttnsubad=6596806129"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link dark_btn"
                  style={{
                    marginRight: '20px', // Add space between the button and next element
                  }}
                >
                  Download App
                </a>
              </li>
              {isAuthenticated ? (
                <li
                  className="nav-item has_dropdown"
                  style={{
                    position: 'relative',
                    width: 'fit-content',
                  }}
                >
                  <span
                    className="nav-link"
                    onClick={toggleDropdown}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      width: '100%',
                      padding: '10px 20px',
                      backgroundColor: '#fff',
                      color: '#6c63ff',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderRadius: '5px',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    Welcome, {username} <i className="icofont-rounded-down"></i>
                  </span>
                  <div
                    className="sub_menu"
                    style={{
                      display: dropdownOpen ? 'block' : 'none',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      minWidth: '100%',
                      backgroundColor: '#fff',
                      borderRadius: '5px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      marginTop: '5px',
                      zIndex: 1000,
                    }}
                  >
                    <ul
                      style={{
                        margin: 0,
                        padding: 0,
                        listStyleType: 'none',
                        width: '100%',
                      }}
                    >
                      <li
                        style={{
                          width: '100%',
                        }}
                      >
                        <span
                          onClick={() => navigate(`/matchmaker-home/${matchMakerId}`)}
                          className="nav-link"
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            padding: '10px 20px',
                            color: '#333',
                            textAlign: 'left',
                            fontWeight: '500',
                          }}
                        >
                          Home Page
                        </span>
                      </li>
                      <li
                        style={{
                          width: '100%',
                        }}
                      >
                        <span
                          onClick={() =>
                            navigate(`/matchmaker-home/${matchMakerId}/view-candidates`)
                          }
                          className="nav-link"
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            padding: '10px 20px',
                            color: '#333',
                            textAlign: 'left',
                            fontWeight: '500',
                          }}
                        >
                          View Candidates
                        </span>
                      </li>
                      <li
                        style={{
                          width: '100%',
                        }}
                      >
                        <span
                          onClick={() =>
                            navigate(`/matchmaker-home/${matchMakerId}/create-candidate`)
                          }
                          className="nav-link"
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            padding: '10px 20px',
                            color: '#333',
                            textAlign: 'left',
                            fontWeight: '500',
                          }}
                        >
                          Create Candidate
                        </span>
                      </li>

                      <li
                        style={{
                          width: '100%',
                        }}
                      >
                        <span
                          onClick={handleSignOut}
                          className="nav-link"
                          style={{
                            cursor: 'pointer',
                            display: 'block',
                            padding: '10px 20px',
                            color: '#333',
                            textAlign: 'left',
                            fontWeight: '500',
                          }}
                        >
                          Sign Out
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/sign-in" className="nav-link default-btn login">
                      Log In
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/sign-up" className="nav-link default-btn signup">
                      Sign Up
                    </Link>
                  </li> */}
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
