import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import img from '../../../assets/images/anim_line.png'
import blueapp from '../../../assets/images/appstore_blue.png'
import blue from '../../../assets/images/googleplay_blue.png'
import screen from '../../../assets/images/PreLoginView.png'
import screen1 from '../../../assets/images/LoginView.png'

const Main = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.getElementsByClassName('free_text');
            if (elements.length > 0) {
                const position = elements[0].getBoundingClientRect();
                const backdrop = document.getElementsByClassName("purple_backdrop");

                if (position.top < window.innerHeight && position.bottom >= 0) {
                    if (backdrop.length > 0) {
                        backdrop[0].style.opacity = "1"; 
                    }
                } else {
                    if (backdrop.length > 0) {
                        backdrop[0].style.opacity = "0"; 
                    }
                }
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to run once on mount

    return (
        <>
            <section className="row_am free_app_section review_freeapp" id="getstarted">
                <div className="container">
                    <div className={`free_app_inner aos-init ${animate ? "aos-animate" : ""}`} data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100"> 
                        <div className="anim_line dark_bg">
                            {Array(9).fill().map((_, index) => (
                                <span key={index}><img src={img} alt="anim_line" /></span>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="free_text">
                                    <div className="section_title">
                                        <h2>Let’s download free from apple and play store</h2>
                                        <p>Download the MiSoulmate app for free on the App Store and Google Play. Connect and find your match effortlessly!</p>
                                    </div>
                                    <ul className="app_btn">
                                        <li>
                                            <a href="https://apps.apple.com/us/app/misoulmate-muslim-marriage/id6596806129?itscg=30200&itsct=apps_box_link&mttnsubad=6596806129" target="_blank" rel="noopener noreferrer">
                                                <img src={blueapp} alt="App Store" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src={blue} alt="Google Play Store" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="free_img">
                                    <img src={screen} alt="Device Image" />
                                    <img className="mobile_mockup" src={screen1} alt="Mobile Mockup" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="purple_backdrop"></div>
        </>
    )
}

export default Main;
