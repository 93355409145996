import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/sunni_mahr_marriage.png";

function HowShouldMahrBeSetInSunniMarriage() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>How Should Mahr Be Set in Sunni Marriage? | Islamic Insights</title>
                <meta name="description" content="Learn about the importance of mahr in Sunni marriage, how it should be set, and the spiritual and legal significance of this Islamic tradition." />
                <meta name="keywords" content="Mahr in Sunni Islam, Sunni Marriage, Mahr Setting, Islamic Marriage Contract, Islamic Traditions, Muslim Marriage" />
                <meta property="og:title" content="How Should Mahr Be Set in Sunni Marriage? | Islamic Insights" />
                <meta property="og:description" content="Explore the Islamic tradition of mahr, how it is determined, and its role in ensuring fairness, respect, and security in a Sunni marriage." />
                <meta property="og:image" content="/path/to/share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/how-should-mahr-be-set-in-sunni-marriage" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="How Should Mahr Be Set in Sunni Marriage?" 
                paragraph="Understand the importance of mahr in Sunni Islam and how it is set to ensure fairness and respect in the marriage contract." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 8, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>How Should Mahr Be Set in Sunni Marriage?</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="How Mahr Is Set in Sunni Marriage" />
                        </div>
                        <div className="info">
                            <p>Mahr, or the marriage gift, is a fundamental component of a Sunni marriage contract. It is an obligatory gift from the groom to the bride, reflecting respect and the commitment of the husband towards his wife. The amount of mahr is typically agreed upon by both parties before the marriage ceremony, and it is intended to serve as a form of security for the wife, giving her a sense of financial independence and protection.</p>

                            <h3>What is Mahr?</h3>
                            <p>In Islam, mahr is a required part of the marriage contract. It is the husband’s responsibility to give mahr to his wife as a symbol of his commitment to the marriage and his respect for her. The Quran instructs, "And give the women [whom you marry] their due compensation as an obligation" (Quran 4:4). This gift is meant to be a token of respect and a means to ensure the wife’s security in the marriage. Unlike a dowry, which may be given to the bride’s family, mahr belongs solely to the wife.</p>

                            <h3>Determining the Amount of Mahr</h3>
                            <p>The amount of mahr should be agreed upon by both the bride and groom, ideally before the wedding. It can range from a small sum to a more significant amount, depending on cultural norms, the financial situation of both parties, and personal preferences. The Prophet Muhammad (PBUH) said, "The best marriage is the one with the least burden" (Sunan Ibn Majah). Therefore, the mahr should be reasonable and reflect the circumstances of the couple.</p>

                            <p>There is no fixed amount prescribed for mahr in the Quran, as it is meant to be something that both parties can agree on. It could be in the form of money, jewelry, or property, or even something intangible, such as the husband’s commitment to treat his wife with respect and kindness. However, the mahr should be something that the wife can freely use, as it is a gift from her husband.</p>

                            <h3>Types of Mahr</h3>
                            <p>Mahr can be divided into two categories: the immediate mahr (mu'ajjal) and the deferred mahr (muwajjal).</p>
                            <ul>
                                <li><strong>Immediate Mahr (Mu'ajjal):</strong> This is the portion of the mahr that is given to the wife at the time of marriage or shortly after.</li>
                                <li><strong>Deferred Mahr (Muwajjal):</strong> This part of the mahr is delayed, typically to be paid later if the marriage ends through divorce or death. The deferred portion can be agreed upon in the marriage contract.</li>
                            </ul>

                            <h3>Importance of Mahr in Sunni Marriage</h3>
                            <p>Mahr plays a vital role in the marriage contract, ensuring that the wife’s rights are upheld. It establishes a foundation of mutual respect and understanding. The mahr protects the wife’s financial rights and provides her with security, especially in the event of divorce. It also symbolizes the groom’s serious commitment to the marriage and his willingness to fulfill his obligations as a husband.</p>

                            <p>Additionally, mahr serves as a reminder that marriage is a partnership based on mutual respect, and it ensures that both parties have a vested interest in the relationship’s success. The wife’s right to mahr is non-negotiable and cannot be waived unless she voluntarily agrees to forgo it.</p>

                            <h3>Conclusion</h3>
                            <p>In conclusion, mahr is an essential part of a Sunni marriage, reflecting the respect, commitment, and legal obligations between the husband and wife. It provides financial security to the wife, emphasizes her autonomy, and helps protect her rights within the marriage. While the amount and form of mahr may vary, its significance remains the same across the Muslim world as a symbol of trust, respect, and mutual commitment. By setting the mahr in a fair and reasonable manner, both parties contribute to a strong foundation for their marriage.</p>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"The best marriage is the one with the least burden." – Prophet Muhammad (PBUH),</h2>
                                <p><span className="name">Sunan Ibn Majah</span></p>
                            </div>

                        </div>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 8, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Mahr,</span></li>
                                <li><span>Sunni Marriage,</span></li>
                                <li><span>Marriage in Islam</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default  HowShouldMahrBeSetInSunniMarriage;
