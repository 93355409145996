/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMatchMaker = /* GraphQL */ `
  mutation CreateMatchMaker(
    $input: CreateMatchMakerInput!
    $condition: ModelMatchMakerConditionInput
  ) {
    createMatchMaker(input: $input, condition: $condition) {
      id
      username
      email
      password
      bureauName
      clientCount
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMatchMaker = /* GraphQL */ `
  mutation UpdateMatchMaker(
    $input: UpdateMatchMakerInput!
    $condition: ModelMatchMakerConditionInput
  ) {
    updateMatchMaker(input: $input, condition: $condition) {
      id
      username
      email
      password
      bureauName
      clientCount
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMatchMaker = /* GraphQL */ `
  mutation DeleteMatchMaker(
    $input: DeleteMatchMakerInput!
    $condition: ModelMatchMakerConditionInput
  ) {
    deleteMatchMaker(input: $input, condition: $condition) {
      id
      username
      email
      password
      bureauName
      clientCount
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeetingDetails = /* GraphQL */ `
  mutation CreateMeetingDetails(
    $input: CreateMeetingDetailsInput!
    $condition: ModelMeetingDetailsConditionInput
  ) {
    createMeetingDetails(input: $input, condition: $condition) {
      id
      meetingId
      userAId
      userBId
      audioHostUrl
      audioFallbackUrl
      screenDataUrl
      screenSharingUrl
      screenViewingUrl
      signalingUrl
      turnControlUrl
      eventIngestionUrl
      mediaRegion
      userAInMeeting
      userBInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeetingDetails = /* GraphQL */ `
  mutation UpdateMeetingDetails(
    $input: UpdateMeetingDetailsInput!
    $condition: ModelMeetingDetailsConditionInput
  ) {
    updateMeetingDetails(input: $input, condition: $condition) {
      id
      meetingId
      userAId
      userBId
      audioHostUrl
      audioFallbackUrl
      screenDataUrl
      screenSharingUrl
      screenViewingUrl
      signalingUrl
      turnControlUrl
      eventIngestionUrl
      mediaRegion
      userAInMeeting
      userBInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeetingDetails = /* GraphQL */ `
  mutation DeleteMeetingDetails(
    $input: DeleteMeetingDetailsInput!
    $condition: ModelMeetingDetailsConditionInput
  ) {
    deleteMeetingDetails(input: $input, condition: $condition) {
      id
      meetingId
      userAId
      userBId
      audioHostUrl
      audioFallbackUrl
      screenDataUrl
      screenSharingUrl
      screenViewingUrl
      signalingUrl
      turnControlUrl
      eventIngestionUrl
      mediaRegion
      userAInMeeting
      userBInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      eventType
      notificationType
      likedUserDeviceToken
      likedUserName
      liked_user_id
      disliked_user_id
      deviceToken
      body
      senderName
      matchID
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      eventType
      notificationType
      likedUserDeviceToken
      likedUserName
      liked_user_id
      disliked_user_id
      deviceToken
      body
      senderName
      matchID
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      eventType
      notificationType
      likedUserDeviceToken
      likedUserName
      liked_user_id
      disliked_user_id
      deviceToken
      body
      senderName
      matchID
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatroomUser = /* GraphQL */ `
  mutation CreateChatroomUser(
    $input: CreateChatroomUserInput!
    $condition: ModelChatroomUserConditionInput
  ) {
    createChatroomUser(input: $input, condition: $condition) {
      id
      userId
      chatroomCity
      userName
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatroomUser = /* GraphQL */ `
  mutation UpdateChatroomUser(
    $input: UpdateChatroomUserInput!
    $condition: ModelChatroomUserConditionInput
  ) {
    updateChatroomUser(input: $input, condition: $condition) {
      id
      userId
      chatroomCity
      userName
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatroomUser = /* GraphQL */ `
  mutation DeleteChatroomUser(
    $input: DeleteChatroomUserInput!
    $condition: ModelChatroomUserConditionInput
  ) {
    deleteChatroomUser(input: $input, condition: $condition) {
      id
      userId
      chatroomCity
      userName
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch(
    $input: CreateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    createMatch(input: $input, condition: $condition) {
      id
      message_id
      Messages {
        nextToken
        __typename
      }
      userID
      liked_user_id
      match_id
      liked_user_id_device_token
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    updateMatch(input: $input, condition: $condition) {
      id
      message_id
      Messages {
        nextToken
        __typename
      }
      userID
      liked_user_id
      match_id
      liked_user_id_device_token
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch(
    $input: DeleteMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    deleteMatch(input: $input, condition: $condition) {
      id
      message_id
      Messages {
        nextToken
        __typename
      }
      userID
      liked_user_id
      match_id
      liked_user_id_device_token
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      liked_user_id
      disliked_user_id
      userID
      likedUserDeviceToken
      likedUserName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      liked_user_id
      disliked_user_id
      userID
      likedUserDeviceToken
      likedUserName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      liked_user_id
      disliked_user_id
      userID
      likedUserDeviceToken
      likedUserName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      age
      gender
      bio
      avatar
      userStatus
      dob
      username
      deviceToken
      height
      countryGrown
      educationLevel
      profession
      familySituation
      liveWith
      children
      wantChildren
      onPlatform
      moveAbroad
      religious
      smoker
      drinker
      eatHalal
      practiceSalat
      muslimSector
      Likes {
        nextToken
        __typename
      }
      locationLongitude
      locationLatitude
      city
      country
      hajjUmrah
      verified
      birthYear
      isLiveInQueue
      isInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      age
      gender
      bio
      avatar
      userStatus
      dob
      username
      deviceToken
      height
      countryGrown
      educationLevel
      profession
      familySituation
      liveWith
      children
      wantChildren
      onPlatform
      moveAbroad
      religious
      smoker
      drinker
      eatHalal
      practiceSalat
      muslimSector
      Likes {
        nextToken
        __typename
      }
      locationLongitude
      locationLatitude
      city
      country
      hajjUmrah
      verified
      birthYear
      isLiveInQueue
      isInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      age
      gender
      bio
      avatar
      userStatus
      dob
      username
      deviceToken
      height
      countryGrown
      educationLevel
      profession
      familySituation
      liveWith
      children
      wantChildren
      onPlatform
      moveAbroad
      religious
      smoker
      drinker
      eatHalal
      practiceSalat
      muslimSector
      Likes {
        nextToken
        __typename
      }
      locationLongitude
      locationLatitude
      city
      country
      hajjUmrah
      verified
      birthYear
      isLiveInQueue
      isInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      senderName
      body
      creationDate
      matchID
      deviceToken
      read
      readDate
      chatroomCity
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      senderName
      body
      creationDate
      matchID
      deviceToken
      read
      readDate
      chatroomCity
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      senderName
      body
      creationDate
      matchID
      deviceToken
      read
      readDate
      chatroomCity
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
