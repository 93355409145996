/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMatchMaker = /* GraphQL */ `
  query GetMatchMaker($id: ID!) {
    getMatchMaker(id: $id) {
      id
      username
      email
      password
      bureauName
      clientCount
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMatchMakers = /* GraphQL */ `
  query ListMatchMakers(
    $filter: ModelMatchMakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchMakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        password
        bureauName
        clientCount
        verified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeetingDetails = /* GraphQL */ `
  query GetMeetingDetails($id: ID!) {
    getMeetingDetails(id: $id) {
      id
      meetingId
      userAId
      userBId
      audioHostUrl
      audioFallbackUrl
      screenDataUrl
      screenSharingUrl
      screenViewingUrl
      signalingUrl
      turnControlUrl
      eventIngestionUrl
      mediaRegion
      userAInMeeting
      userBInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeetingDetails = /* GraphQL */ `
  query ListMeetingDetails(
    $filter: ModelMeetingDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meetingId
        userAId
        userBId
        audioHostUrl
        audioFallbackUrl
        screenDataUrl
        screenSharingUrl
        screenViewingUrl
        signalingUrl
        turnControlUrl
        eventIngestionUrl
        mediaRegion
        userAInMeeting
        userBInMeeting
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      eventType
      notificationType
      likedUserDeviceToken
      likedUserName
      liked_user_id
      disliked_user_id
      deviceToken
      body
      senderName
      matchID
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventType
        notificationType
        likedUserDeviceToken
        likedUserName
        liked_user_id
        disliked_user_id
        deviceToken
        body
        senderName
        matchID
        user_id_device_token
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatroomUser = /* GraphQL */ `
  query GetChatroomUser($id: ID!) {
    getChatroomUser(id: $id) {
      id
      userId
      chatroomCity
      userName
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatroomUsers = /* GraphQL */ `
  query ListChatroomUsers(
    $filter: ModelChatroomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatroomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        chatroomCity
        userName
        userStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      message_id
      Messages {
        nextToken
        __typename
      }
      userID
      liked_user_id
      match_id
      liked_user_id_device_token
      user_id_device_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMatches = /* GraphQL */ `
  query ListMatches(
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message_id
        userID
        liked_user_id
        match_id
        liked_user_id_device_token
        user_id_device_token
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      liked_user_id
      disliked_user_id
      userID
      likedUserDeviceToken
      likedUserName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        liked_user_id
        disliked_user_id
        userID
        likedUserDeviceToken
        likedUserName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const likesByUserID = /* GraphQL */ `
  query LikesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        liked_user_id
        disliked_user_id
        userID
        likedUserDeviceToken
        likedUserName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      age
      gender
      bio
      avatar
      userStatus
      dob
      username
      deviceToken
      height
      countryGrown
      educationLevel
      profession
      familySituation
      liveWith
      children
      wantChildren
      onPlatform
      moveAbroad
      religious
      smoker
      drinker
      eatHalal
      practiceSalat
      muslimSector
      Likes {
        nextToken
        __typename
      }
      locationLongitude
      locationLatitude
      city
      country
      hajjUmrah
      verified
      birthYear
      isLiveInQueue
      isInMeeting
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        age
        gender
        bio
        avatar
        userStatus
        dob
        username
        deviceToken
        height
        countryGrown
        educationLevel
        profession
        familySituation
        liveWith
        children
        wantChildren
        onPlatform
        moveAbroad
        religious
        smoker
        drinker
        eatHalal
        practiceSalat
        muslimSector
        locationLongitude
        locationLatitude
        city
        country
        hajjUmrah
        verified
        birthYear
        isLiveInQueue
        isInMeeting
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      senderName
      body
      creationDate
      matchID
      deviceToken
      read
      readDate
      chatroomCity
      userStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderName
        body
        creationDate
        matchID
        deviceToken
        read
        readDate
        chatroomCity
        userStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByMatchID = /* GraphQL */ `
  query MessagesByMatchID(
    $matchID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByMatchID(
      matchID: $matchID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderName
        body
        creationDate
        matchID
        deviceToken
        read
        readDate
        chatroomCity
        userStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
