import React from 'react';
import { Link } from 'react-router-dom';
import BGImg from "../../../assets/images/bread_crumb_bg.png";
import Shape1 from "../../../assets/images/banner-shape1.png";
import Shape2 from "../../../assets/images/banner-shape2.png";
import Shape3 from "../../../assets/images/banner-shape3.png";
import FeaturedBlogPost from '../FeaturedBlogPost/FeaturedBlogPost';
import AllBlog from './AllStories7';

const Main = () => {
    return (
        <>
            {/* Breadcrumb Implementation */}
            <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="container">
                    <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                    <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                    <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                    <div className="bred_text">
                        <h1>Blogs</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>»</span></li>
                            <li><Link to="/blog">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <AllBlog />
        </>
    );
};

export default Main;
