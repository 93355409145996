import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        { imgSrc: "/assets/images/muslim_couple_in_marriage.png", time: "December 8th, 2024", title: "Marriage in Islam Half of Faith", content: "Discover how marriage is regarded as half of faith in Islam and its spiritual significance.", link: "/marriage-in-islam-half-of-faith" },
        { imgSrc: "/assets/images/muslim_wedding_benefits.png", time: "December 8th, 2024", title: "Benefits of Marriage in Islam", content: "Explore the blessings and benefits of marriage in Islam, fostering love, unity, and spiritual growth.", link: "/benefits-of-marriage-in-islam" },
        { imgSrc: "/assets/images/importance_of_family_islam.png", time: "December 8th, 2024", title: "Importance of Family in Islam", content: "Discover the importance of family in Islam and its role in shaping society.", link: "/importance-of-family-in-islam" },
        { imgSrc: "/assets/images/benefits_of_marriage_in_islam.png", time: "December 8th, 2024", title: "Benefits of Marriage in Islam", content: "Marriage offers numerous benefits in Islam, promoting love, support, and unity.", link: "/benefits-of-marriage-in-islam" },
        { imgSrc: "/assets/images/muslim_couple_embracing_tradition.png", time: "December 8th, 2024", title: "Why Do Muslims Marry", content: "Understand the religious, spiritual, and practical reasons for marriage in Islam.", link: "/why-do-muslims-marry" },
        { imgSrc: "/assets/images/compatible_muslim_couple.png", time: "December 8th, 2024", title: "Importance of Compatibility in Islamic Marriage", content: "Discover why compatibility is vital for a successful Islamic marriage.", link: "/importance-of-compatibility-in-islamic-marriage" },
        { imgSrc: "/assets/images/muslim_couple_marriage_faith.png", time: "December 8th, 2024", title: "How Marriage Fulfills Faith in Islam", content: "Learn how marriage is a completion of faith and a path to spiritual growth.", link: "/how-marriage-fulfills-faith-in-islam" },
        { imgSrc: "/assets/images/sunni_mahr_marriage.png", time: "December 8th, 2024", title: "How Should Mahr Be Set in Sunni Marriage", content: "Understand the process and rules of setting Mahr in Sunni marriage.", link: "/how-should-mahr-be-set-in-sunni-marriage" },
        { imgSrc: "/assets/images/happy-muslim-couple.png", time: "December 8th, 2024", title: "The Importance of Marriage in Islam", content: "Explore the key reasons why marriage is vital in Islam.", link: "/the-importance-of-marriage-in-islam" },
        { imgSrc: "/assets/images/marriage_strengthens_faith.png", time: "December 8th, 2024", title: "How Marriage Strengthens Faith in Islam", content: "Learn how marriage helps Muslims strengthen their faith and devotion to Allah.", link: "/how-marriage-strengthens-faith-in-islam" },
        { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "Rights of a Wife in Sunni Islam", content: "Explore the rights of a wife in Sunni Islam and the responsibilities of the husband.", link: "/rights-of-a-wife-in-sunni-islam" },
        { imgSrc: "/assets/images/muslim_wedding_celebration.png", time: "December 8th, 2024", title: "Why Marriage Is Important in Sunni Islam", content: "Discover why marriage is regarded as an essential act of worship in Sunni Islam.", link: "/why-marriage-is-important-in-sunni-islam" },
        { imgSrc: "/assets/images/rights_responsibilities_spouses_sunni_islam.png", time: "December 8th, 2024", title: "Rights and Responsibilities of Spouses in Sunni Islam", content: "Learn the essential rights and responsibilities of spouses in Sunni marriage.", link: "/rights-and-responsibilities-of-spouses-in-sunni-islam" },
        { imgSrc: "/assets/images/marriage_in_sunni_islam.png", time: "December 8th, 2024", title: "Role of Marriage in Sunni Islam", content: "Understand the role and purpose of marriage in Sunni Islam.", link: "/role-of-marriage-in-sunni-islam" },
        { imgSrc: "/assets/images/sunni_marriage.png", time: "December 8th, 2024", title: "How Marriage Is Viewed in Sunni Islam", content: "Learn how marriage is viewed and honored in Sunni Islamic tradition.", link: "/how-marriage-is-viewed-in-sunni-islam" },
        { imgSrc: "/assets/images/mahr_in_sunni_marriage.png", time: "December 8th, 2024", title: "How Mahr Is Set in Sunni Marriage", content: "Explore the importance of Mahr and its role in Sunni marriage.", link: "/how-mahr-is-set-in-sunni-marriage" },
        { imgSrc: "/assets/images/sunni_marriage_importance.png", time: "December 8th, 2024", title: "Why Marriage Is Important in Sunni Islam", content: "Discover why marriage is essential and significant in Sunni teachings.", link: "/why-marriage-is-important-in-sunni-islam" },
        { imgSrc: "/assets/images/sunni_marriage_spiritual_importance.png", time: "December 8th, 2024", title: "Sunni Marriage Spiritual Importance", content: "Learn how marriage spiritually benefits spouses in Sunni Islam.", link: "/sunni-marriage-spiritual-importance" },
        { imgSrc: "/assets/images/rights_of_wife_in_sunni_islam.png", time: "December 8th, 2024", title: "Rights of a Wife in Sunni Islam", content: "Understand the rights and responsibilities of a wife in a Sunni marriage.", link: "/rights-of-a-wife-in-sunni-islam" },
        { imgSrc: "/assets/images/why-is-marriage-important-in-islam.png", time: "December 8th, 2024", title: "Why Is Marriage Important in Islam", content: "Explore the reasons why marriage is essential in Islamic teachings.", link: "/why-is-marriage-important-in-islam" },
        { imgSrc: "/assets/images/sunni_islam_marriage.jpg", time: "December 8th, 2024", title: "Significance of Marriage in Sunni Islam", content: "Learn about the social, legal, and spiritual significance of marriage in Sunni Islam.", link: "/significance-of-marriage-in-sunni-islam" },
        { imgSrc: "/assets/images/sunni_marriage.jpg", time: "December 8th, 2024", title: "Role of Wali in Sunni Marriage", content: "Discover the vital role of the Wali in a Sunni marriage.", link: "/role-of-wali-in-sunni-marriage" },
        { imgSrc: "/assets/images/sunni_husband_rights.png", time: "December 8th, 2024", title: "Rights and Duties of a Husband in Sunni Islam", content: "Understand the rights and duties of a husband in a Sunni marriage.", link: "/rights-and-duties-of-a-husband-in-sunni-islam" },
        { imgSrc: "/assets/images/witnesses_in_sunni_marriage.png", time: "December 8th, 2024", title: "Role of Witnesses in Sunni Marriage", content: "Learn about the role of witnesses in a Sunni marriage.", link: "/role-of-witnesses-in-sunni-marriage" },
        { imgSrc: "/assets/images/mahr_importance_sunni_marriage.png", time: "December 8th, 2024", title: "Importance of Mahr in Sunni Marriage", content: "Understand the importance and role of Mahr in Sunni marriage.", link: "/importance-of-mahr-in-sunni-marriage" },
        { imgSrc: "/assets/images/remarriage_in_islam.png", time: "December 8th, 2024", title: "Remarriage in Islam", content: "Learn the rules and process for remarriage in Islam.", link: "/remarriage-in-islam" },

        { imgSrc: "/assets/images/remarriage_after_divorce.png", time: "December 8th, 2024", title: "Remarriage After Divorce in Sunni Islam", content: "A guide on the Islamic perspective and process of remarriage after divorce.", link: "/remarriage-after-divorce-in-sunni-islam" },
        { imgSrc: "/assets/images/how-husband-provides-for-wife-sunni-islam.png", time: "December 8th, 2024", title: "How Husband Provides for Wife in Sunni Islam", content: "Explore the financial and emotional support a husband provides in Sunni Islam.", link: "/how-husband-provides-for-wife-in-sunni-islam" },
        { imgSrc: "/assets/images/religious_duties_in_marriage.png", time: "December 8th, 2024", title: "How Religious Duties are Prioritized in Marriage", content: "Unravel the religious obligations and responsibilities of Muslim couples.", link: "/how-are-religious-duties-prioritized-in-marriage" },
        { imgSrc: "/assets/images/personal_space_in_marriage.png", time: "December 8th, 2024", title: "How to Maintain Personal Space in Marriage", content: "Learn how personal space plays a key role in a successful Muslim marriage.", link: "/how-to-maintain-personal-space-in-marriage" },
        { imgSrc: "/assets/images/sunni-marriage-contract-modified.png", time: "December 8th, 2024", title: "Can Sunni Marriage Contract Be Modified?", content: "Discover how modifications to the Sunni marriage contract can be made.", link: "/can-sunni-marriage-contract-be-modified" },
        { imgSrc: "/assets/images/re-marriage_after_iddah_muslim_couple.png", time: "December 8th, 2024", title: "Process of Remarriage After Iddah", content: "Learn about the rules and process for remarriage after Iddah in Islam.", link: "/process-of-remarriage-after-iddah" },
        { imgSrc: "/assets/images/role_of_wali_in_sunni_engagement.png", time: "December 8th, 2024", title: "Role of Wali in Sunni Engagement", content: "Understand the role and importance of a Wali in the engagement process.", link: "/what-is-the-role-of-a-wali-in-sunni-engagement" },
        { imgSrc: "/assets/images/non_muslim_witnesses_marriage.png", time: "December 8th, 2024", title: "Non-Muslim Witnesses in Muslim Marriage", content: "Can non-Muslims be witnesses in an Islamic marriage? Find out more.", link: "/non-muslim-witnesses-in-muslim-marriage" },
        { imgSrc: "/assets/images/affection_in_sunni_marriage.png", time: "December 8th, 2024", title: "How Affection Is Expressed in Sunni Marriage", content: "How affection and love strengthen the bond in a Sunni marriage.", link: "/how-affection-is-expressed-in-sunni-marriage" },
        { imgSrc: "/assets/images/family_values_upheld.png", time: "December 8th, 2024", title: "How Should Family Values Be Upheld", content: "The role of family values in fostering healthy Sunni marriages.", link: "/how-should-family-values-be-upheld" },
        { imgSrc: "/assets/images/forgiveness_in_marriage_islam.png", time: "December 8th, 2024", title: "How Forgiveness Is Practiced in Marriage", content: "The power of forgiveness in strengthening Islamic marriages.", link: "/how-forgiveness-is-practiced-in-marriage" },
        { imgSrc: "/assets/images/joint_family_systems_ruling_in_islam.png", time: "December 8th, 2024", title: "Joint Family Systems Ruling in Islam", content: "Discover Islam's stance on joint family systems in marriage.", link: "/joint-family-systems-ruling-in-islam" },
        { imgSrc: "/assets/images/how_is_trust_maintained_in_marriage.png", time: "December 8th, 2024", title: "How Is Trust Maintained in Marriage", content: "Learn how trust is built and maintained in successful marriages.", link: "/how-is-trust-maintained-in-marriage" },
        { imgSrc: "/assets/images/responsibility_shared_in_muslim_marriage.png", time: "December 8th, 2024", title: "How Responsibility Is Shared in Muslim Marriage", content: "How responsibilities are shared equally in Muslim marriages.", link: "/how-is-responsibility-shared-in-muslim-marriage" },
        { imgSrc: "/assets/images/importance_of_nikah_khutbah_main_pic.png", time: "December 8th, 2024", title: "Importance of Nikah Khutbah", content: "The spiritual and practical significance of the Nikah Khutbah.", link: "/importance-of-nikah-khutbah" },
        { imgSrc: "/assets/images/sunni-couple-with-pet.png", time: "December 8th, 2024", title: "Can Sunni Couples Keep Pets?", content: "The Islamic perspective on pets in a Sunni household.", link: "/can-sunni-couples-keep-pets" },
        { imgSrc: "/assets/images/mahr_delay_islam.png", time: "December 8th, 2024", title: "Mahr Delay Ruling", content: "Is delaying the Mahr permissible in Islam? Learn the rulings.", link: "/mahr-delay-ruling" },
        { imgSrc: "/assets/images/children-taught-about-marriage.png", time: "December 8th, 2024", title: "How Are Children Taught About Marriage in Islam", content: "Why and how children are educated about marriage in Islam.", link: "/how-are-children-taught-about-marriage-in-islam" },
        { imgSrc: "/assets/images/islamic_marital_boundaries.png", time: "December 8th, 2024", title: "What Are Islamic Marital Boundaries", content: "Explore the boundaries and limitations set within a marital relationship.", link: "/what-are-islamic-marital-boundaries" },
        { imgSrc: "/assets/images/how_food_should_be_handled_in_islam.png", time: "December 8th, 2024", title: "How Food Should Be Handled in Islam", content: "Learn how food is managed and respected in Islamic households.", link: "/how-food-should-be-handled-in-islam" },
        { imgSrc: "/assets/images/ruling_on_family_conflicts_in_islam.png", time: "December 8th, 2024", title: "Ruling on Family Conflicts in Islam", content: "Learn how Islam advises resolving family conflicts effectively.", link: "/ruling-on-family-conflicts-in-islam" },
        { imgSrc: "/assets/images/religious_values_upheld_main_image.png", time: "December 8th, 2024", title: "How Are Religious Values Upheld", content: "Upholding religious values is essential for a successful marriage.", link: "/how-are-religious-values-upheld" },
        { imgSrc: "/assets/images/community_support_in_muslim_marriage.png", time: "December 8th, 2024", title: "Role of Community Support in Muslim Marriage", content: "How the community can play a vital role in supporting Muslim marriages.", link: "/role-of-community-support-in-muslim-marriage" },
        { imgSrc: "/assets/images/how-spouses-should-honor-in-laws.png", time: "December 8th, 2024", title: "How Should Spouses Honor In-Laws", content: "A guide to honoring in-laws according to Islamic teachings.", link: "/how-should-spouses-honor-in-laws" },
        { imgSrc: "/assets/images/muslim_couple_spiritual_benefits.png", time: "December 8th, 2024", title: "Spiritual Benefits of Marriage in Sunni Islam", content: "Spiritual benefits that a Muslim couple gains in marriage.", link: "/spiritual-benefits-marriage-sunni-islam" },
        { imgSrc: "/assets/images/sunni_household_responsibilities.png", time: "December 8th, 2024", title: "Marital Responsibilities in Sunni Household", content: "Discover the roles and responsibilities in a Sunni Islamic household.", link: "/marital-responsibilities-in-sunni-household" },
        { imgSrc: "/assets/images/respect_in_marriage_sunni.png", time: "December 8th, 2024", title: "Respect in Marriage Sunni Islam", content: "Understand the importance of respect in a successful marriage.", link: "/respect-in-marriage-sunni-islam" },
        { imgSrc: "/assets/images/muslim_couple_sunshine.png", time: "December 8th, 2024", title: "Ethical Guidelines for Supporting Spouse in Sunni Islam", content: "Explore the ethical guidelines for spouses supporting each other in Sunni Islam.", link: "/ethical-guidelines-for-supporting-spouse-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marriage_financial_responsibility.png", time: "December 8th, 2024", title: "How Is Financial Responsibility Shared in Sunni Marriages", content: "Learn how financial responsibilities are shared in Sunni marriages.", link: "/how-is-financial-responsibility-shared-in-sunni-marriages" },
    { imgSrc: "/assets/images/household_roles_sunni_islam.png", time: "December 8th, 2024", title: "Household Roles in Sunni Islam", content: "Discover the various household roles of spouses in Sunni Islam.", link: "/household-roles-sunni-islam" },
    { imgSrc: "/assets/images/beautiful_muslim_family.png", time: "December 8th, 2024", title: "Rewards for Maintaining Family Bonds in Sunni Islam", content: "Understand the spiritual rewards for maintaining strong family bonds in Islam.", link: "/rewards-for-maintaining-family-bonds-in-sunni-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "Trust in Marriage in Sunni Islam", content: "Learn how trust is established and nurtured in a Sunni Islamic marriage.", link: "/trust-in-marriage-sunni" },
    { imgSrc: "/assets/images/muslim_couple_sunshine_1.png", time: "December 8th, 2024", title: "Ethical Guidelines for Supporting Spouse in Sunni Islam", content: "Explore the ethical guidelines for supporting a spouse according to Sunni principles.", link: "/ethical-guidelines-for-supporting-spouse-in-sunni-islam" },
    { imgSrc: "/assets/images/honesty_in_married_life.png", time: "December 8th, 2024", title: "Honesty in Marriage in Sunni Islam", content: "Discover the role of honesty in fostering love and trust in Sunni marriages.", link: "/honesty-in-marriage-sunni-islam" },
    { imgSrc: "/assets/images/halal_argument_resolution_couple.png", time: "December 8th, 2024", title: "How Can Couples Resolve Arguments in a Halal Manner in Sunni Islam", content: "Learn the steps to resolving marital arguments in a halal manner.", link: "/how-can-couple-resolve-arguments-halal-manner-sunni-islam" },
    { imgSrc: "/assets/images/family_support_in_marriage_sunni_community.png", time: "December 8th, 2024", title: "Family Support in Sunni Marriage", content: "Discover how family support impacts the strength of Sunni marriages.", link: "/family-support-in-sunni-marriage" },
    { imgSrc: "/assets/images/sunny_spouse_blessings_image.png", time: "December 8th, 2024", title: "How Helping Your Spouse Brings Blessings", content: "Learn how helping your spouse can bring divine blessings and happiness.", link: "/how-helping-your-spouse-brings-blessings" },
    { imgSrc: "/assets/images/supportive_muslim_wife.png", time: "December 8th, 2024", title: "How Wife Supports Husband in Islam", content: "Explore how a wife supports her husband emotionally, financially, and spiritually.", link: "/how-wife-supports-husband-in-islam" },
    { imgSrc: "/assets/images/kindness_family_bonds_islam.png", time: "December 8th, 2024", title: "How Kindness Strengthens Family Bonds in Islam", content: "Discover how kindness can strengthen family bonds in a Muslim household.", link: "/how-kindness-strengthens-family-bonds-in-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple_1.png", time: "December 8th, 2024", title: "Importance of Good Manners Between Spouses in Sunni Islam", content: "Learn why good manners between spouses are essential for a successful marriage.", link: "/importance-of-good-manners-between-spouses-in-sunni-islam" },
    { imgSrc: "/assets/images/responsibility_towards_children_sunni_islam.png", time: "December 8th, 2024", title: "Responsibility Towards Children in Sunni Islam", content: "Explore the parental responsibilities towards children according to Sunni Islam.", link: "/responsibility-towards-children-in-sunni-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple_2.png", time: "December 8th, 2024", title: "How Modesty Influences Marriage in Sunni Muslim Tradition", content: "Discover the impact of modesty on marriage in Sunni Muslim tradition.", link: "/how-modesty-influences-marriage-in-sunni-muslim-tradition" },
    { imgSrc: "/assets/images/mutual_respect_harmony_muslim_couple.png", time: "December 8th, 2024", title: "How Mutual Respect Fosters a Harmonious Marriage in Sunni Islam", content: "Learn how mutual respect fosters harmony in a Sunni Islamic marriage.", link: "/how-mutual-respect-fosters-a-harmonious-marriage-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marriage_mutual_sacrifices.png", time: "December 8th, 2024", title: "How Are Mutual Sacrifices Valued in Sunni Muslim Marriages", content: "Discover the role of mutual sacrifices in strengthening a marriage.", link: "/how-are-mutual-sacrifices-valued-in-sunni-muslim-marriages" },
    { imgSrc: "/assets/images/sunni_marriage_forgiveness.png", time: "December 8th, 2024", title: "How Does Forgiveness Play a Role in Sunni Muslim Marriages", content: "Learn how forgiveness strengthens marital bonds in Sunni Islam.", link: "/how-does-forgiveness-play-a-role-in-sunni-muslim-marriages" },
    { imgSrc: "/assets/images/spiritual_growth_muslim_couple.png", time: "December 8th, 2024", title: "How Sunni Couples Grow Spiritually", content: "Discover the journey of spiritual growth for Sunni couples.", link: "/how-sunni-couples-grow-spiritually" },
    { imgSrc: "/assets/images/respect_in_sunni_marriages.png", time: "December 8th, 2024", title: "Respect in Sunni Marriages", content: "Learn the importance of respect in a healthy Sunni marriage.", link: "/respect-in-sunni-marriages" },
    { imgSrc: "/assets/images/sunni_marriage_blessings.png", time: "December 8th, 2024", title: "How Are Marriage Blessings Perceived in Sunni Islam", content: "Discover how blessings in marriage are seen in Sunni Islamic teachings.", link: "/how-are-marriage-blessings-perceived-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marriage_financial_responsibility.png", time: "December 8th, 2024", title: "How Is Financial Responsibility Shared in Sunni Marriages", content: "Learn how financial responsibilities are shared in Sunni marriages.", link: "/how-is-financial-responsibility-shared-in-sunni-marriages" },
    { imgSrc: "/assets/images/marriage_in_islam_wedding_couple.png", time: "December 8th, 2024", title: "How Does the Quran Guide Married Life in Sunni Islam", content: "Discover how the Quran guides the married life of Sunni couples.", link: "/how-does-the-quran-guide-married-life-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marital_responsibilities.png", time: "December 8th, 2024", title: "How Marital Responsibilities Are Approached", content: "Learn how marital responsibilities are approached in Sunni Islam.", link: "/how-marital-responsibilities-are-approached" },
    { imgSrc: "/assets/images/patience_in_marriage.png", time: "December 8th, 2024", title: "Patience in Marriage for Longevity in Sunni Islam", content: "Explore how patience leads to longevity in Sunni Islamic marriages.", link: "/patience-in-marriage-longevity-sunni-islam" },
    { imgSrc: "/assets/images/muslim_couple_sunshine.png", time: "December 8th, 2024", title: "Ethical Guidelines for Supporting Spouse in Sunni Islam", content: "Explore the ethical guidelines for spouses supporting each other in Sunni Islam.", link: "/ethical-guidelines-for-supporting-spouse-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marriage_financial_responsibility.png", time: "December 8th, 2024", title: "How Is Financial Responsibility Shared in Sunni Marriages", content: "Learn how financial responsibilities are shared in Sunni marriages.", link: "/how-is-financial-responsibility-shared-in-sunni-marriages" },
    { imgSrc: "/assets/images/household_roles_sunni_islam.png", time: "December 8th, 2024", title: "Household Roles in Sunni Islam", content: "Discover the various household roles of spouses in Sunni Islam.", link: "/household-roles-sunni-islam" },
    { imgSrc: "/assets/images/beautiful_muslim_family.png", time: "December 8th, 2024", title: "Rewards for Maintaining Family Bonds in Sunni Islam", content: "Understand the spiritual rewards for maintaining strong family bonds in Islam.", link: "/rewards-for-maintaining-family-bonds-in-sunni-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "Trust in Marriage in Sunni Islam", content: "Learn how trust is established and nurtured in a Sunni Islamic marriage.", link: "/trust-in-marriage-sunni" },
    { imgSrc: "/assets/images/muslim_couple_sunshine_1.png", time: "December 8th, 2024", title: "Ethical Guidelines for Supporting Spouse in Sunni Islam", content: "Explore the ethical guidelines for supporting a spouse according to Sunni principles.", link: "/ethical-guidelines-for-supporting-spouse-in-sunni-islam" },
    { imgSrc: "/assets/images/honesty_in_married_life.png", time: "December 8th, 2024", title: "Honesty in Marriage in Sunni Islam", content: "Discover the role of honesty in fostering love and trust in Sunni marriages.", link: "/honesty-in-marriage-sunni-islam" },
    { imgSrc: "/assets/images/halal_argument_resolution_couple.png", time: "December 8th, 2024", title: "How Can Couples Resolve Arguments in a Halal Manner in Sunni Islam", content: "Learn the steps to resolving marital arguments in a halal manner.", link: "/how-can-couple-resolve-arguments-halal-manner-sunni-islam" },
    { imgSrc: "/assets/images/family_support_in_marriage_sunni_community.png", time: "December 8th, 2024", title: "Family Support in Sunni Marriage", content: "Discover how family support impacts the strength of Sunni marriages.", link: "/family-support-in-sunni-marriage" },
    { imgSrc: "/assets/images/sunny_spouse_blessings_image.png", time: "December 8th, 2024", title: "How Helping Your Spouse Brings Blessings", content: "Learn how helping your spouse can bring divine blessings and happiness.", link: "/how-helping-your-spouse-brings-blessings" },
    { imgSrc: "/assets/images/supportive_muslim_wife.png", time: "December 8th, 2024", title: "How Wife Supports Husband in Islam", content: "Explore how a wife supports her husband emotionally, financially, and spiritually.", link: "/how-wife-supports-husband-in-islam" },
    { imgSrc: "/assets/images/kindness_family_bonds_islam.png", time: "December 8th, 2024", title: "How Kindness Strengthens Family Bonds in Islam", content: "Discover how kindness can strengthen family bonds in a Muslim household.", link: "/how-kindness-strengthens-family-bonds-in-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple_1.png", time: "December 8th, 2024", title: "Importance of Good Manners Between Spouses in Sunni Islam", content: "Learn why good manners between spouses are essential for a successful marriage.", link: "/importance-of-good-manners-between-spouses-in-sunni-islam" },
    { imgSrc: "/assets/images/responsibility_towards_children_sunni_islam.png", time: "December 8th, 2024", title: "Responsibility Towards Children in Sunni Islam", content: "Explore the parental responsibilities towards children according to Sunni Islam.", link: "/responsibility-towards-children-in-sunni-islam" },
    { imgSrc: "/assets/images/good_looking_muslim_couple_2.png", time: "December 8th, 2024", title: "How Modesty Influences Marriage in Sunni Muslim Tradition", content: "Discover the impact of modesty on marriage in Sunni Muslim tradition.", link: "/how-modesty-influences-marriage-in-sunni-muslim-tradition" },
    { imgSrc: "/assets/images/mutual_respect_harmony_muslim_couple.png", time: "December 8th, 2024", title: "How Mutual Respect Fosters a Harmonious Marriage in Sunni Islam", content: "Learn how mutual respect fosters harmony in a Sunni Islamic marriage.", link: "/how-mutual-respect-fosters-a-harmonious-marriage-in-sunni-islam" },
    { imgSrc: "/assets/images/sunni_marriage_mutual_sacrifices.png", time: "December 8th, 2024", title: "How Are Mutual Sacrifices Valued in Sunni Muslim Marriages", content: "Discover the role of mutual sacrifices in strengthening a marriage.", link: "/how-are-mutual-sacrifices-valued-in-sunni-muslim-marriages" },
    { imgSrc: "/assets/images/sunni_marriage_forgiveness.png", time: "December 8th, 2024", title: "How Does Forgiveness Play a Role in Sunni Muslim Marriages", content: "Learn how forgiveness strengthens marital bonds in Sunni Islam.", link: "/how-does-forgiveness-play-a-role-in-sunni-muslim-marriages" }
];
    

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog6" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7" className="active">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog8" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>

                </div>
            </section>
        </>
    );
}

export default AllBlog;
