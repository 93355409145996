import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useParams, useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { getUser } from "../../../graphql/queries";
import { updateUser } from "../../../graphql/mutations";
import BGImg from "../../../assets/images/bread_crumb_bg.png";

const client = generateClient();

const EditCandidate = () => {
    const { candidateId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        age: '',
        gender: '',
        bio: '',
        avatar: '',
        userStatus: 'Offline',
        dob: '1999/01/01',
        username: '',
        deviceToken: '',
        height: '',
        countryGrown: '',
        educationLevel: '',
        profession: '',
        familySituation: '',
        liveWith: '',
        children: '',
        wantChildren: '',
        onPlatform: '',
        moveAbroad: '',
        religious: '',
        smoker: '',
        drinker: '',
        eatHalal: '',
        practiceSalat: '',
        muslimSector: '',
        locationLongitude: '-79.347015',
        locationLatitude: '43.651070',
        city: '',
        country: '',
        hajjUmrah: '',
        verified: '',
        birthYear: '',
        isLiveInQueue: false,
        isInMeeting: false,
        matchmakerID: '',
        isPremiumUser: false,
        likesRemaining: 0,
        messagesRemaining: 0,
        liveMatchesRemaining: 0,
        preferences: '',
        personalities: '',
        isPicture1Blur: false,
        isPicture2Blur: false,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await client.graphql({
                    query: getUser,
                    variables: { id: candidateId },
                });
                const user = response.data.getUser;

                if (user) {
                    setFormData({
                        ...formData,
                        ...user
                    });
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [candidateId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const updatedData = {
                id: candidateId,
                name: formData.name,
                age: formData.age,
                gender: formData.gender,
                bio: formData.bio,
                avatar: formData.avatar,
                username: formData.username,
                city: formData.city,
                country: formData.country,
                height: formData.height,
                educationLevel: formData.educationLevel,
                profession: formData.profession,
                familySituation: formData.familySituation,
                liveWith: formData.liveWith,
                children: formData.children,
                wantChildren: formData.wantChildren,
                smoker: formData.smoker,
                drinker: formData.drinker,
                eatHalal: formData.eatHalal,
                practiceSalat: formData.practiceSalat,
                muslimSector: formData.muslimSector,
                hajjUmrah: formData.hajjUmrah,
                isPremiumUser: formData.isPremiumUser,
            };

            console.log("Submitting data:", updatedData);

            const result = await client.graphql({
                query: updateUser,
                variables: { input: updatedData },
            });

            if (result.data.updateUser) {
                alert("Profile updated successfully!");
                navigate(-1);  // Navigate back to previous page
            } else {
                throw new Error("Failed to update the user. Please check input fields.");
            }
        } catch (error) {
            console.error("Error updating candidate:", error);
            alert("An error occurred while updating the profile. Check console for details.");
        }
    };



    if (loading) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#555" }}>Loading Inquiring Users...</p>
            </>
        );
    }

    if (error) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "red" }}>Error loading candidates: {error}</p>
            </>
        );
    }
    return (
        <div style={styles.container}>

            <div style={styles.breadcrumbWrapper}>
                <div
                    className="bred_crumb"
                    style={{
                        ...styles.breadcrumb,
                        backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                    }}
                ></div>
            </div>

            <div style={styles.formWrapper}>
                <h2 style={styles.formTitle}>Edit Candidate Profile</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your full name"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Age:</label>
                        <input
                            type="number"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            placeholder="Enter your age"
                            style={styles.input}
                        />
                    </div>

                    {/* <div style={styles.formGroup}>
                        <label style={styles.label}>Gender:</label>
                        <Select
                            name="gender"
                            value={{ label: formData.gender, value: formData.gender }}
                            onChange={(selectedOption) =>
                                handleChange({ target: { name: 'gender', value: selectedOption.value } })
                            }
                            options={[
                                { value: "Male 👨", label: "Male 👨" },
                                { value: "Female 👩", label: "Female 👩" },
                            ]}
                            styles={styles.dropdown}
                        />
                    </div> */}

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Bio:</label>
                        <textarea
                            name="bio"
                            value={formData.bio}
                            onChange={handleChange}
                            placeholder="Write something about yourself"
                            style={styles.textarea}
                        ></textarea>
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>City:</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter your city"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Country:</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            placeholder="Enter your country"
                            style={styles.input}
                        />
                    </div>

                    {/* <div style={styles.formGroup}>
                        <label style={styles.label}>Height:</label>
                        <input
                            type="text"
                            name="height"
                            value={formData.height}
                            onChange={handleChange}
                            placeholder="Enter your height"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Religious:</label>
                        <input
                            type="text"
                            name="religious"
                            value={formData.religious}
                            onChange={handleChange}
                            placeholder="Are you religious?"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Smoker:</label>
                        <input
                            type="text"
                            name="smoker"
                            value={formData.smoker}
                            onChange={handleChange}
                            placeholder="Do you smoke?"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Eat Halal:</label>
                        <input
                            type="text"
                            name="eatHalal"
                            value={formData.eatHalal}
                            onChange={handleChange}
                            placeholder="Do you eat halal food?"
                            style={styles.input}
                        />
                    </div>

                    <div style={styles.formGroup}>
                        <label style={styles.label}>Is Premium User:</label>
                        <input
                            type="checkbox"
                            name="isPremiumUser"
                            checked={formData.isPremiumUser}
                            onChange={(e) =>
                                handleChange({ target: { name: 'isPremiumUser', value: e.target.checked } })
                            }
                            style={styles.checkbox}
                        />
                    </div> */}

                    <div style={styles.buttonGroup}>
                        <button type="submit" style={styles.submitButton}>Update Profile</button>
                        <button type="button" style={styles.cancelButton} onClick={() => window.history.back()}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
    },
    breadcrumb: {
        width: '100%',
        maxWidth: '1200px',
        marginBottom: '20px',
    },
    formWrapper: {
        width: '100%',
        maxWidth: '600px',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    formTitle: {
        textAlign: 'center',
        fontSize: '1.8rem',
        color: '#333',
        marginBottom: '20px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '1rem',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        height: '100px',
    },
    dropdown: {
        control: (base) => ({
            ...base,
            padding: '10px',
            fontSize: '1rem',
        }),
    },
    checkbox: {
        marginLeft: '10px',
        transform: 'scale(1.2)',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    submitButton: {
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        padding: '12px 20px',
        borderRadius: '5px',
        fontSize: '1rem',
        cursor: 'pointer',
        flex: '1',
        marginRight: '10px',
    },
    cancelButton: {
        backgroundColor: '#ccc',
        color: '#333',
        border: 'none',
        padding: '12px 20px',
        borderRadius: '5px',
        fontSize: '1rem',
        cursor: 'pointer',
        flex: '1',
    },
    breadcrumb: {
        width: '100%',
        height: '150px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    breadcrumbWrapper: {
        width: '100vw',
        position: 'relative',
        left: 0,
        top: 0,
        backgroundColor: '#f4f4f4',
    },
};

export default EditCandidate;