import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/can_sunni_couple_get_engaged_without_family_approval.png",
            time: "December 11th, 2024",
            title: "Can Sunni Couple Get Engaged Without Family Approval?",
            content: "Exploring the permissibility of engagement without family consent...",
            link: "/can-sunni-couple-get-engaged-without-family-approval"
        },
        {
            imgSrc: "/assets/images/what_is_the_role_of_consent_in_sunni_marriage.png",
            time: "December 13th, 2024",
            title: "Role of Consent in Sunni Marriage",
            content: "Learn why consent is crucial in Sunni marriage...",
            link: "/role-of-consent-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/premarital_counseling_sunni_islam.png",
            time: "December 14th, 2024",
            title: "Premarital Counseling in Sunni Islam",
            content: "Benefits and importance of premarital counseling...",
            link: "/premarital-counseling-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/iddah_observance_rules.png",
            time: "December 15th, 2024",
            title: "Iddah Observance Rules",
            content: "An overview of the rules of Iddah for Sunni women...",
            link: "/iddah-observance-rules"
        },
        {
            imgSrc: "/assets/images/child_custody_in_sunni_islam.png",
            time: "December 16th, 2024",
            title: "Child Custody in Sunni Islam",
            content: "Understanding child custody arrangements in Sunni law...",
            link: "/child-custody-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/inheritance_rights_in_marriage.png",
            time: "December 17th, 2024",
            title: "Inheritance Rights in Marriage",
            content: "Explore inheritance rights between spouses in Sunni Islam...",
            link: "/inheritance-rights-in-marriage"
        },
        {
            imgSrc: "/assets/images/sunni_woman_remarrying.png",
            time: "December 18th, 2024",
            title: "Sunni Woman Remarrying",
            content: "Guidelines on remarriage for Sunni women...",
            link: "/sunni-woman-remarrying"
        },
        {
            imgSrc: "/assets/images/sunni_islam_second_marriage.png",
            time: "December 19th, 2024",
            title: "Sunni Islam Second Marriage",
            content: "Insights into the practices of second marriages...",
            link: "/sunni-islam-second-marriage"
        },
        {
            imgSrc: "/assets/images/proper_age_for_marriage_in_sunni_islam.png",
            time: "December 20th, 2024",
            title: "Proper Age for Marriage in Sunni Islam",
            content: "Understanding the recommended age for marriage...",
            link: "/proper-age-for-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marital_discord_resolution_sunni_islam.png",
            time: "December 21st, 2024",
            title: "Marital Discord Resolution in Sunni Islam",
            content: "How marital conflicts are addressed within Sunni teachings...",
            link: "/marital-discord-resolution-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/obligations_of_parents_in_sunni_wedding.png",
            time: "December 22nd, 2024",
            title: "Obligations of Parents in Sunni Wedding",
            content: "The role of parents in facilitating a Sunni marriage...",
            link: "/obligations-of-parents-in-sunni-wedding"
        },
        {
            imgSrc: "/assets/images/how_is_family_planning_addressed_in_sunni_islam.png",
            time: "December 23rd, 2024",
            title: "Family Planning in Sunni Islam",
            content: "Explore how family planning is viewed in Sunni teachings...",
            link: "/how-is-family-planning-addressed-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/can_sunni_husband_restrict_travel.png",
            time: "December 24th, 2024",
            title: "Can Sunni Husband Restrict Travel?",
            content: "Understanding the boundaries of travel restrictions in marriage...",
            link: "/can-sunni-husband-restrict-travel"
        },
        {
            imgSrc: "/assets/images/prenuptial_agreements_in_sunni_islam.png",
            time: "December 25th, 2024",
            title: "Prenuptial Agreements in Sunni Islam",
            content: "Learn about prenuptial agreements and their role in marriage...",
            link: "/prenuptial-agreements-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/healthy_muslim_couple.png",
            time: "December 26th, 2024",
            title: "Healthy Muslim Couple",
            content: "Traits and practices of a healthy marriage in Islam...",
            link: "/healthy-muslim-couple"
        },
        {
            imgSrc: "/assets/images/sunni_woman_working_outside_home.png",
            time: "December 27th, 2024",
            title: "Sunni Woman Working Outside Home",
            content: "Explore the considerations for a working wife in Sunni Islam...",
            link: "/sunni-woman-working-outside-home"
        },
        {
            imgSrc: "/assets/images/inheritance_between_spouses_sunni_islam.png",
            time: "December 28th, 2024",
            title: "Inheritance Between Spouses in Sunni Islam",
            content: "Understanding the laws of inheritance for married couples...",
            link: "/inheritance-between-spouses-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_wedding_ceremony_requirements.png",
            time: "December 29th, 2024",
            title: "Sunni Wedding Ceremony Requirements",
            content: "Essential elements of a Sunni wedding ceremony...",
            link: "/sunni-wedding-ceremony-requirements"
        },
        {
            imgSrc: "/assets/images/sunni-marriage-preparation.png",
            time: "December 30th, 2024",
            title: "Sunni Marriage Preparation",
            content: "Tips for preparing for marriage according to Sunni principles...",
            link: "/sunni-marriage-preparation"
        },
        {
            imgSrc: "/assets/images/role_of_wali_in_sunni_nikah.png",
            time: "December 31st, 2024",
            title: "Role of Wali in Sunni Nikah",
            content: "The responsibilities and significance of the Wali in Nikah...",
            link: "/role-of-wali-in-sunni-nikah"
        },
        {
            imgSrc: "/assets/images/mahr-sunni-marriage.png",
            time: "January 1st, 2025",
            title: "Mahr in Sunni Marriage",
            content: "A guide to understanding the concept and importance of Mahr...",
            link: "/mahr-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/how-should-nikah-be-registered-sunni-islam.png",
            time: "January 2nd, 2025",
            title: "How Should Nikah Be Registered in Sunni Islam?",
            content: "The formalities of registering a Nikah in Sunni Islam...",
            link: "/how-should-nikah-be-registered-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni-view-romantic-love-before-marriage.png",
            time: "January 3rd, 2025",
            title: "Sunni View on Romantic Love Before Marriage",
            content: "Islamic teachings on love and affection before marriage...",
            link: "/sunni-view-on-romantic-love-before-marriage"
        },
        {
            imgSrc: "/assets/images/wedding_expenses_sunni_culture.png",
            time: "January 4th, 2025",
            title: "Wedding Expenses in Sunni Culture",
            content: "Understanding the financial responsibilities in Sunni weddings...",
            link: "/wedding-expenses-in-sunni-culture"
        },
        {
            imgSrc: "/assets/images/purpose_of_marriage_sunni_islam.png",
            time: "January 5th, 2025",
            title: "Purpose of Marriage in Sunni Islam",
            content: "Exploring the deeper purpose of marriage in Islam...",
            link: "/purpose-of-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_nikah_witnesses.png",
            time: "January 6th, 2025",
            title: "Sunni Nikah Witnesses",
            content: "Why witnesses are crucial for a valid marriage in Islam...",
            link: "/sunni-nikah-witnesses"
        },
        {
            imgSrc: "/assets/images/marriage_strengthening_faith.png",
            time: "January 7th, 2025",
            title: "Marriage Strengthening Faith",
            content: "How marriage plays a role in strengthening faith in Islam...",
            link: "/marriage-strengthening-faith"
        },
        {
            imgSrc: "/assets/images/sunni_islam_dowry_jahaz.png",
            time: "January 8th, 2025",
            title: "Sunni Islam Dowry (Jahaz)",
            content: "Understanding the cultural practice of Dowry or Jahaz...",
            link: "/sunni-islam-dowry-jahaz"
        },
        {
            imgSrc: "/assets/images/halal_dating_sunni_islam.png",
            time: "January 9th, 2025",
            title: "Halal Dating in Sunni Islam",
            content: "A guide to maintaining halal relationships...",
            link: "/halal-dating-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marriage_proposals_sunni_culture.png",
            time: "January 10th, 2025",
            title: "Marriage Proposals in Sunni Culture",
            content: "An overview of marriage proposal traditions in Sunni Islam...",
            link: "/marriage-proposals-in-sunni-culture"
        },
        {
            imgSrc: "/assets/images/sunni_couple_marriage_without_family_involvement.png",
            time: "January 11th, 2025",
            title: "Sunni Couple Marriage Without Family Involvement",
            content: "Examining the implications of marriage without family support...",
            link: "/sunni-couple-marriage-without-family-involvement"
        },
        {
            imgSrc: "/assets/images/marriage_to_revert_in_sunni_islam.png",
            time: "January 12th, 2025",
            title: "Marriage to a Revert in Sunni Islam",
            content: "Guidelines on marrying a revert in Sunni Islam...",
            link: "/how-does-sunni-islam-view-marriage-to-a-revert"
        },
        {
            imgSrc: "/assets/images/sunni-couple-finances.png",
            time: "January 13th, 2025",
            title: "Sunni Couple Finances",
            content: "Managing finances in a Sunni marriage...",
            link: "/sunni-couple-finances"
        },
        {
            imgSrc: "/assets/images/premarital_education_in_sunni_islam.png",
            time: "January 14th, 2025",
            title: "Premarital Education in Sunni Islam",
            content: "The role of premarital education in strengthening marriage...",
            link: "/premarital-education-in-sunni-islam"
        }
    ];
    

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                               <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog4" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5" className="active">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog6" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>

                </div>
            </section>
        </>
    );
}

export default AllBlog;
