import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/significance-of-walima-in-sunni-islam.png",
            time: "January 15th, 2025",
            title: "Significance of Walima in Sunni Islam",
            content: "Understanding the importance of Walima in a Sunni wedding...",
            link: "/significance-of-walima-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_wedding_invitation_etiquette.png",
            time: "January 16th, 2025",
            title: "Sunni Wedding Invitation Etiquette",
            content: "Learn the etiquette and customs for wedding invitations...",
            link: "/sunni-wedding-invitation-etiquette"
        },
        {
            imgSrc: "/assets/images/sunni-couple-family-boundaries.jpg",
            time: "January 17th, 2025",
            title: "Sunni Couple Family Boundaries",
            content: "How to establish healthy boundaries with family in marriage...",
            link: "/sunni-couple-family-boundaries"
        },
        {
            imgSrc: "/assets/images/honesty_in_sunni_marriage.png",
            time: "January 18th, 2025",
            title: "Honesty in Sunni Marriage",
            content: "Exploring the value of honesty in marital relationships...",
            link: "/honesty-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/marriage_disputes_in_sunni_culture.png",
            time: "January 19th, 2025",
            title: "Marriage Disputes in Sunni Culture",
            content: "How marriage disputes are resolved in Sunni communities...",
            link: "/marriage-disputes-in-sunni-culture"
        },
        {
            imgSrc: "/assets/images/wali-responsibility-sunni-islam.png",
            time: "January 20th, 2025",
            title: "Wali Responsibility in Sunni Islam",
            content: "The responsibilities of the Wali in a Sunni marriage...",
            link: "/wali-responsibility-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marriage_gifts_sunni_wedding.png",
            time: "January 21st, 2025",
            title: "Marriage Gifts in Sunni Wedding",
            content: "Guidance on exchanging gifts in Sunni wedding ceremonies...",
            link: "/marriage-gifts-in-sunni-wedding"
        },
        {
            imgSrc: "/assets/images/financial_transparency_in_marriage.png",
            time: "January 22nd, 2025",
            title: "Financial Transparency in Marriage",
            content: "The importance of financial transparency in a Sunni marriage...",
            link: "/financial-transparency-in-marriage"
        },
        {
            imgSrc: "/assets/images/sunni_couple_living_separately.png",
            time: "January 23rd, 2025",
            title: "Sunni Couple Living Separately",
            content: "The permissibility and circumstances for separate living...",
            link: "/sunni-couple-living-separately"
        },
        {
            imgSrc: "/assets/images/modesty_in_islam.png",
            time: "January 24th, 2025",
            title: "Modesty in Islam",
            content: "Understanding modesty as a core value in Islamic marriage...",
            link: "/modesty-in-islam"
        },
        {
            imgSrc: "/assets/images/benefits_of_marriage_islam.png",
            time: "January 25th, 2025",
            title: "Benefits of Marriage in Islam",
            content: "Exploring the spiritual and social benefits of marriage...",
            link: "/benefits-of-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/sunni_islam_parental_involvement_in_marriage.png",
            time: "January 26th, 2025",
            title: "Parental Involvement in Sunni Marriage",
            content: "The role of parents in supporting Sunni marriage...",
            link: "/parental-involvement-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/role_of_extended_family_in_sunni_marriage.png",
            time: "January 27th, 2025",
            title: "Role of Extended Family in Sunni Marriage",
            content: "How extended family impacts Sunni marital life...",
            link: "/role-of-extended-family-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/sunni_islam_widow_remarriage.png",
            time: "January 28th, 2025",
            title: "Widow Remarriage in Sunni Islam",
            content: "Understanding the guidelines for remarriage after widowhood...",
            link: "/widow-remarriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/protocol-for-ending-marriage-in-sunni-islam.png",
            time: "January 29th, 2025",
            title: "Protocol for Ending Marriage in Sunni Islam",
            content: "Guidelines for responsibly ending a marriage...",
            link: "/protocol-for-ending-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni-couple-conflict-resolution.png",
            time: "January 30th, 2025",
            title: "Sunni Couple Conflict Resolution",
            content: "Approaches to resolving conflicts in Sunni marriages...",
            link: "/sunni-couple-conflict-resolution"
        },
        {
            imgSrc: "/assets/images/emotional_support_in_sunni_marriage.png",
            time: "January 31st, 2025",
            title: "Emotional Support in Sunni Marriage",
            content: "The importance of emotional support between spouses...",
            link: "/emotional-support-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/wifes-right-to-mahr-sunni-islam.png",
            time: "February 1st, 2025",
            title: "Wife's Right to Mahr in Sunni Islam",
            content: "Understanding the wife's right to Mahr...",
            link: "/wifes-right-to-mahr-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how-marriage-registration-should-be-completed.png",
            time: "February 2nd, 2025",
            title: "How Marriage Registration Should Be Completed",
            content: "Steps for registering a marriage in Sunni Islam...",
            link: "/how-marriage-registration-should-be-completed"
        },
        {
            imgSrc: "/assets/images/how-should-property-be-divided-in-sunni-divorce.jpg",
            time: "February 3rd, 2025",
            title: "How Should Property Be Divided in Sunni Divorce?",
            content: "Guidance on property division upon divorce...",
            link: "/how-should-property-be-divided-in-sunni-divorce"
        },
        {
            imgSrc: "/assets/images/acceptable_reasons_for_divorce_in_sunni_islam.jpg",
            time: "February 4th, 2025",
            title: "Acceptable Reasons for Divorce in Sunni Islam",
            content: "Exploring valid reasons for divorce...",
            link: "/acceptable-reasons-for-divorce-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/respect_in_sunni_marriage.png",
            time: "February 5th, 2025",
            title: "Respect in Sunni Marriage",
            content: "Why respect is fundamental in marriage...",
            link: "/respect-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/role_of_mediation_in_sunni_divorce.jpg",
            time: "February 6th, 2025",
            title: "Role of Mediation in Sunni Divorce",
            content: "How mediation can help in resolving marital disputes...",
            link: "/role-of-mediation-in-sunni-divorce"
        },
        {
            imgSrc: "/assets/images/age_difference_in_sunni_marriage.png",
            time: "February 7th, 2025",
            title: "Age Difference in Sunni Marriage",
            content: "The impact of age differences in marriage...",
            link: "/age-difference-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/can-sunni-couple-marry-after-converting-to-islam.png",
            time: "February 8th, 2025",
            title: "Can Sunni Couple Marry After Converting to Islam?",
            content: "Understanding the permissibility of marriage after conversion...",
            link: "/can-sunni-couple-marry-after-converting-to-islam"
        },
        {
            imgSrc: "/assets/images/how_wali_should_counsel_bride_in_sunni_islam.png",
            time: "February 9th, 2025",
            title: "How Wali Should Counsel Bride in Sunni Islam",
            content: "Guidelines on the Wali's counsel before marriage...",
            link: "/how-wali-should-counsel-bride-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/family-honor-sunni-marriage.png",
            time: "February 10th, 2025",
            title: "Family Honor in Sunni Marriage",
            content: "Understanding the importance of family honor in marriage...",
            link: "/family-honor-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/cultural_practices_sunni_marriage.png",
            time: "February 11th, 2025",
            title: "Cultural Practices in Sunni Marriage",
            content: "Exploring cultural customs in Sunni marriage...",
            link: "/cultural-practices-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/ruling_on_delaying_marriage_in_sunni_islam.png",
            time: "February 12th, 2025",
            title: "Ruling on Delaying Marriage in Sunni Islam",
            content: "Examining the ruling on delaying marriage...",
            link: "/ruling-on-delaying-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marriage_proposal_decline_sunni_islam.png",
            time: "February 13th, 2025",
            title: "Marriage Proposal Decline in Sunni Islam",
            content: "Guidelines on declining a marriage proposal...",
            link: "/marriage-proposal-decline-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how-should-marriage-be-discussed-with-children-in-sunni-islam.png",
            time: "February 14th, 2025",
            title: "How Should Marriage Be Discussed with Children in Sunni Islam?",
            content: "Approaching marriage topics with children...",
            link: "/how-should-marriage-be-discussed-with-children-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marrying-cousin-sunni-islam.png",
            time: "February 15th, 2025",
            title: "Marrying a Cousin in Sunni Islam",
            content: "Guidance on cousin marriage in Sunni communities...",
            link: "/marrying-cousin-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/anger_management_in_sunni_marriage.png",
            time: "February 16th, 2025",
            title: "Anger Management in Sunni Marriage",
            content: "Addressing anger in marital relationships...",
            link: "/anger-management-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/sunni_view_remarriage_after_divorce.png",
            time: "February 17th, 2025",
            title: "Sunni View on Remarriage After Divorce",
            content: "Examining remarriage guidelines after divorce...",
            link: "/sunni-view-on-remarriage-after-divorce"
        },
        {
            imgSrc: "/assets/images/husband-providing-for-wife-sunni-islam.png",
            time: "February 18th, 2025",
            title: "Husband Providing for Wife in Sunni Islam",
            content: "Responsibilities of the husband as a provider...",
            link: "/husband-providing-for-wife-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/iddah-rules-sunni-islam.png",
            time: "February 19th, 2025",
            title: "Iddah Rules in Sunni Islam",
            content: "Understanding the observance of Iddah...",
            link: "/iddah-rules-in-sunni-islam"
        }
    ];
    

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog5" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6" className="active">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog7" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
