import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/muslim_marriage_reddit_guide.png",
            time: "October 22nd, 2024",
            title: "Muslim Marriage Reddit Guide",
            content: "Explore Reddit discussions on Muslim marriage, compatibility, and real-life advice...",
            link: "/muslim-marriage-reddit"
        },
        {
            imgSrc: "/assets/images/islamic_marriage_rules_guide.png",
            time: "October 23rd, 2024",
            title: "Islamic Marriage Rules Guide",
            content: "Learn about the rules of Islamic marriage, including rights, duties, and expectations...",
            link: "/islamic-marriage-rules"
        },
        {
            imgSrc: "/assets/images/muslim_universal_marriage_bureau.png",
            time: "October 24th, 2024",
            title: "Muslim Universal Marriage Bureau",
            content: "Discover the global matchmaking services offered by the Muslim Universal Marriage Bureau...",
            link: "/muslim-universal-marriage-bureau"
        },
        {
            imgSrc: "/assets/images/muslim_wedding_contract_couple.png",
            time: "October 25th, 2024",
            title: "Muslim Wedding Contract",
            content: "Learn about the Muslim wedding contract and its importance in Islamic marriages...",
            link: "/muslim-wedding-contract"
        },
        {
            imgSrc: "/assets/images/muslim_wedding_rules.png",
            time: "October 26th, 2024",
            title: "Muslim Wedding Rules",
            content: "Understand the rules and guidelines that govern Muslim weddings...",
            link: "/muslim-wedding-rules"
        },
        {
            imgSrc: "/assets/images/islam_and_marriage_muslim_couple.png",
            time: "October 27th, 2024",
            title: "Islam and Marriage",
            content: "Explore the Islamic perspectives on marriage and its spiritual significance...",
            link: "/islam-and-marriage"
        },
        {
            imgSrc: "/assets/images/temporary_marriage_in_islam.png",
            time: "October 28th, 2024",
            title: "Temporary Marriage in Islam",
            content: "Learn about the concept of temporary marriage in Islam and its implications...",
            link: "/temporary-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/christian_and_muslim_marriage.png",
            time: "October 29th, 2024",
            title: "Christian and Muslim Marriage",
            content: "Discover the challenges and solutions in interfaith marriages between Christians and Muslims...",
            link: "/christian-and-muslim-marriage"
        },
        {
            imgSrc: "/assets/images/islamic_nikah_illustration.png",
            time: "October 30th, 2024",
            title: "Islamic Nikah",
            content: "Learn the process and significance of Islamic Nikah...",
            link: "/islamic-nikah"
        },
        {
            imgSrc: "/assets/images/nikah_marriage_ceremony.png",
            time: "October 31st, 2024",
            title: "Nikah Marriage",
            content: "Explore the fundamentals of Nikah and how it forms the basis of a Muslim marriage...",
            link: "/nikah-marriage"
        },
        {
            imgSrc: "/assets/images/muslim_temporary_marriage.png",
            time: "November 1st, 2024",
            title: "Muslim Temporary Marriage",
            content: "Understand the practice of temporary marriages in Islam...",
            link: "/muslim-temporary-marriage"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_contract.png",
            time: "November 2nd, 2024",
            title: "Muslim Marriage Contract",
            content: "Learn about the Muslim marriage contract and its significance in Islam...",
            link: "/muslim-marriage-contract"
        },
        {
            imgSrc: "/assets/images/islamic_matrimonial_site.png",
            time: "November 3rd, 2024",
            title: "Islamic Matrimonial Site",
            content: "Discover how Islamic matrimonial sites help Muslims find suitable life partners...",
            link: "/islamic-matrimonial-site"
        },
        {
            imgSrc: "/assets/images/reddit_islam_marriage.png",
            time: "November 4th, 2024",
            title: "Reddit Islam Marriage",
            content: "Explore Islamic marriage discussions and advice on Reddit...",
            link: "/reddit-islam-marriage"
        },
        {
            imgSrc: "/assets/images/islamic_wedding_traditions.png",
            time: "November 5th, 2024",
            title: "Islamic Wedding",
            content: "Learn about Islamic wedding traditions and ceremonies...",
            link: "/islamic-wedding"
        },
        {
            imgSrc: "/assets/images/nikah_ceremony_muslim_couple.png",
            time: "November 6th, 2024",
            title: "Nikah Ceremony",
            content: "Understand the significance and steps of the Nikah ceremony...",
            link: "/nikah-ceremony"
        },
        {
            imgSrc: "/assets/images/half_our_deen_image.png",
            time: "November 7th, 2024",
            title: "Half Our Deen",
            content: "Explore the spiritual and social importance of marriage in completing half of one's faith...",
            link: "/half-our-deen"
        },
        // {
        //     imgSrc: "/assets/images/mutah_marriage_explained.png",
        //     time: "November 8th, 2024",
        //     title: "Mutah Marriage",
        //     content: "Learn about Mutah marriage and its practice within Islamic communities...",
        //     link: "/mutah-marriage"
        // },
        {
            imgSrc: "/assets/images/muslim_wedding_couple.png",
            time: "November 9th, 2024",
            title: "Muslim Wedding",
            content: "Discover the customs and practices of Muslim weddings...",
            link: "/muslim-wedding"
        },
        {
            imgSrc: "/assets/images/reddit_muslim_marriage_discussion.png",
            time: "November 10th, 2024",
            title: "Reddit Muslim Marriage",
            content: "Explore Reddit discussions on Muslim marriages and real-life experiences...",
            link: "/reddit-muslim-marriage"
        },
        {
            imgSrc: "/assets/images/simple_nikah_ceremony.png",
            time: "November 11th, 2024",
            title: "Simple Nikah",
            content: "Learn about simple Nikah ceremonies and their importance in Islam...",
            link: "/simple-nikah"
        },
        {
            imgSrc: "/assets/images/muslim_traditional_wedding.png",
            time: "November 12th, 2024",
            title: "Muslim Traditional Wedding",
            content: "Discover the traditional customs followed in Muslim weddings...",
            link: "/muslim-traditional-wedding"
        }
    ];

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                             <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog2" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3" className="active">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog4" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
