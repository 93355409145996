import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/mahr_in_sunni_marriage.png"; // Mahr related image

function HowMahrIsSetInSunniMarriage() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>How Mahr is Set in Sunni Marriage | Islamic Insights</title>
                <meta name="description" content="Learn how Mahr (dowry) is set in Sunni marriage, its significance, and its role in Islamic marital contracts. Discover the rights and responsibilities of both spouses." />
                <meta name="keywords" content="Mahr in Sunni marriage, Mahr in Islam, Muslim marriage, Islamic dowry, marriage contract, rights of wife in Islam" />
                <meta property="og:title" content="How Mahr is Set in Sunni Marriage | Islamic Insights" />
                <meta property="og:description" content="Explore the process and significance of setting Mahr in Sunni marriage, an important part of the Islamic marriage contract." />
                <meta property="og:image" content="/path/to/share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/how-mahr-is-set-in-sunni-marriage" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="How Mahr is Set in Sunni Marriage" 
                paragraph="Explore the process and importance of setting Mahr in a Sunni marriage contract." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 8, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>How Mahr is Set in Sunni Marriage</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Mahr in Sunni Marriage" />
                        </div>
                        <div className="info">
                            <p>Mahr, also known as the dowry, is a mandatory gift given by the husband to the wife in Sunni Islamic marriages. It is considered a crucial part of the marriage contract and is an essential right of the wife. The concept of Mahr is deeply rooted in the Islamic tradition, emphasizing the dignity, respect, and financial independence of the woman.</p>

                            <p>The Prophet Muhammad (PBUH) emphasized the importance of Mahr, saying, "The most blessed marriage is the one with the least burden" (Sunan Ibn Majah). This highlights the significance of Mahr being a simple and reasonable gift, fulfilling its purpose without imposing financial hardship on the husband. But how exactly is Mahr set, and what does it signify? Let’s explore.</p>

                            <h3>What is Mahr?</h3>
                            <p>Mahr is a gift given by the husband to his wife at the time of marriage. It can be money, property, or anything of value agreed upon by both parties. In Sunni Islam, Mahr is not a payment for the wife but a gift that acknowledges her rights and secures her financial independence. It can be divided into two parts: the prompt (Mu’ajjal) and the deferred (Muwajjal) Mahr.</p>

                            <h3>Types of Mahr</h3>
                            <ul>
                                <li><strong>Prompt Mahr (Mu’ajjal):</strong> This is the part of Mahr that is paid immediately or during the marriage ceremony. It is often in the form of money or a valuable asset.</li>
                                <li><strong>Deferred Mahr (Muwajjal):</strong> This part is paid later, either on a specific date or upon certain conditions, such as divorce or the wife’s request. It is usually set as an amount that the wife can claim at any time.</li>
                            </ul>

                            <h3>The Significance of Mahr in Islam</h3>
                            <p>Mahr holds great spiritual and legal significance in Islam. It symbolizes the husband's commitment to the wife’s well-being and his respect for her autonomy. The amount of Mahr should be mutually agreed upon by both parties, with neither side imposing an unfair burden on the other. It serves as a form of financial security for the wife, ensuring her independence within the marriage.</p>

                            <p>Additionally, Mahr symbolizes the seriousness of the marital commitment. It is not seen as a form of price for the wife, but as an acknowledgment of her dignity and worth. The payment of Mahr also reflects the Islamic values of fairness, respect, and mutual agreement in the relationship.</p>

                            <h3>How is Mahr Set in a Sunni Marriage?</h3>
                            <p>Setting the Mahr in a Sunni marriage is a process that involves mutual consent. While there is no fixed amount mandated by Islam, the Mahr should be reasonable and based on the financial capability of the husband. The amount can vary depending on the couple’s situation, and it can be discussed and negotiated during the marriage contract.</p>

                            <p>In practice, the Mahr is usually agreed upon during the nikah (marriage) ceremony. Both the husband and wife, along with their representatives (such as the wali of the bride), must consent to the terms. The agreed-upon amount is then written into the marriage contract (aqd). The payment can be made in installments, and the deferred portion can be claimed at any time by the wife, particularly in the event of divorce.</p>

                            <h3>Islamic Guidance on Mahr</h3>
                            <p>The Quran says, "And give the women [whom you marry] their mahr as an obligation" (Quran 4:4). This verse underscores the importance of fulfilling the obligation of Mahr. The Prophet Muhammad (PBUH) also encouraged simplicity in setting the Mahr, stating, "The best of marriages is the one that is simplest." Therefore, while the amount of Mahr can vary, it should always be set with the principles of fairness, simplicity, and mutual respect in mind.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Mahr is a right for the wife and an obligation for the husband.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> It symbolizes respect, dignity, and financial security for the wife.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> It is an important aspect of the marriage contract in Sunni Islam.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Mahr can be paid immediately or deferred according to mutual agreement.</p></li>
                            </ul>
                        </div>

                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                            <h2>"And give the women [whom you marry] their mahr as an obligation." — Quran 4:4</h2>
                        </div>

                        <p>In conclusion, Mahr is a central aspect of Sunni Islamic marriages. It not only protects the rights of the wife but also emphasizes the commitment and respect between spouses. The setting of Mahr should be done thoughtfully and in a way that reflects the Islamic values of fairness, dignity, and mutual respect. By fulfilling this important obligation, both parties can strengthen their marriage and build a foundation of trust and love.</p>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 8, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <span>Tags:</span>
                            <ul>
                                <li><Link to="#">Islamic Marriage</Link></li>
                                <li><Link to="#">Mahr in Sunni Islam</Link></li>
                                <li><Link to="#">Muslim Marriage</Link></li>
                                <li><Link to="#">Islamic Dowry</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default  HowMahrIsSetInSunniMarriage;
