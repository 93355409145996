import React, { useState, useEffect, Fragment } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getUser, listLikes, listMatches, listMessages } from '../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import { Link } from "react-router-dom";

const client = generateClient();

const ViewProfile = () => {
    const { candidateId, matchMakerId } = useParams();
    const location = useLocation();
    const { matchMaker, candidate } = location.state || {};
    const [inquiringUsers, setInquiringUsers] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInquiringUsers = async () => {
            try {
                let allLikes = [];
                let nextToken = null;

                do {
                    const likesResult = await client.graphql({
                        query: listLikes,
                        variables: {
                            filter: {
                                disliked_user_id: { eq: "MatchMaker" },
                                liked_user_id: { eq: candidateId }
                            },
                            nextToken
                        }
                    });

                    const items = likesResult.data.listLikes.items || [];
                    allLikes = [...allLikes, ...items];
                    nextToken = likesResult.data.listLikes.nextToken;
                } while (nextToken);

                if (allLikes.length === 0) {
                    setInquiringUsers([]);
                    setLoading(false);
                    return;
                }

                const usersWithMatch = [];
                for (const like of allLikes) {
                    const userResult = await client.graphql({
                        query: getUser,
                        variables: { id: like.userID }
                    });

                    if (userResult.data.getUser) {
                        const matchResult = await client.graphql({
                            query: listMatches,
                            variables: {
                                filter: {
                                    userID: { eq: like.userID },
                                    liked_user_id: { eq: candidateId }
                                }
                            }
                        });

                        const matchId = matchResult.data.listMatches.items.length > 0
                            ? matchResult.data.listMatches.items[0].match_id
                            : null;

                        usersWithMatch.push({ user: userResult.data.getUser, matchId });
                    }
                }

                setInquiringUsers(usersWithMatch);
                setLoading(false);
                await fetchUnreadMessages(usersWithMatch);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        const fetchUnreadMessages = async (usersWithMatch) => {
            const unreadCountMap = {};
            for (const { matchId } of usersWithMatch) {
                if (matchId) {
                    const messagesResult = await client.graphql({
                        query: listMessages,
                        variables: {
                            filter: {
                                matchID: { eq: matchId },
                                read: { eq: false }
                            }
                        }
                    });

                    unreadCountMap[matchId] = messagesResult.data.listMessages.items.length > 0;
                }
            }
            setUnreadMessages(unreadCountMap);
        };

        fetchInquiringUsers();
    }, [candidateId]);

    const openMessages = (userId, matchId) => {
        navigate(`/matchmaker-home/${matchMakerId}/view-candidate/${candidateId}/messages/${matchId}`, {
            state: { matchMaker, candidate },
        });
    };


    if (loading) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#555" }}>Loading Inquiring Users...</p>
            </>
        );
    }

    if (error) {
        return (
            <>
                {/* Breadcrumb */}
                <div className="bred_crumb_wrapper">
                    <div
                        className="bred_crumb"
                        style={{
                            backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                            padding: "50px 0",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                </div>
                <p style={{ textAlign: "center", fontSize: "1.2rem", color: "red" }}>Error loading candidates: {error}</p>
            </>
        );
    }

    return (
        <Fragment>
            <div className="bred_crumb_wrapper">
                <div
                    className="bred_crumb"
                    style={{
                        backgroundImage: `url('/assets/images/bread_crumb_bg.png')`,
                        padding: "50px 0",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>
            </div>
            <div style={{ textAlign: "left", margin: "40px 0 20px 360px" }}>
                <Link
                    to={`/matchmaker-home/${matchMakerId}/view-candidates`}
                    style={{ color: "#007bff", fontSize: "16px", textDecoration: "none" }}
                >
                    &larr; Back to View Candidates
                </Link>
            </div>

            <div className="view-profile" style={styles.container}>
                <div style={styles.profileSection}>
                    <img
                        src={candidate.avatar || "assets/images/placeholder.png"}
                        alt={candidate.name}
                        style={styles.image}
                    />
                    <h2 style={styles.name}>{candidate.name}</h2>
                    <p style={styles.bio}>{candidate.bio}</p>
                    <p style={styles.detail}><strong>Age:</strong> {candidate.age}</p>
                    <p style={styles.detail}><strong>Gender:</strong> {candidate.gender}</p>
                    <p style={styles.detail}><strong>Country:</strong> {candidate.country}</p>
                    <p style={styles.detail}><strong>City:</strong> {candidate.city}</p>
                    <button
                        style={styles.editButton}
                        onClick={() => navigate(`/matchmaker-home/${matchMakerId}/edit-candidate/${candidateId}`, { state: { candidate: { ...candidate } } })}
                    >
                        Edit Profile
                    </button>
                </div>

                <div style={styles.inquiringUsersSection}>
                    <h3 style={styles.sectionTitle}>Inquiring Users</h3>
                    <div style={styles.inquiringUsersList}>
                        {inquiringUsers.map(({ user, matchId }) => (
                            <div key={user.id} style={styles.userCard}>
                                <div style={styles.imageContainer}>
                                    <img
                                        src={user.avatar || "assets/images/placeholder.png"}
                                        alt={user.name}
                                        style={styles.image}
                                    />
                                    {unreadMessages[matchId] && <div style={styles.redDot}></div>}
                                </div>
                                <h4 style={styles.name}>{user.name}</h4>
                                <p style={styles.detail}><strong>Age:</strong> {user.age}</p>
                                <p style={styles.detail}><strong>Gender:</strong> {user.gender}</p>
                                <button onClick={() => openMessages(user.id, matchId)} style={styles.button}>
                                    Open Messages
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const styles = {
    editButton: {
        backgroundColor: "#28a745",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "6px",
        fontSize: "16px",
        cursor: "pointer",
        marginTop: "20px",
    },
    container: {
        padding: '40px',
        maxWidth: '1000px',
        margin: '0 auto',
        textAlign: 'center',
        backgroundColor: '#f7f7f7',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    profileSection: {
        marginBottom: '30px',
        textAlign: 'center',
    },
    imageContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    image: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
    },
    redDot: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '20px',
        height: '20px',
        backgroundColor: 'red',
        borderRadius: '50%',
        border: '2px solid white',
    },
    name: {
        fontSize: '1.5rem',
        margin: '10px 0',
        color: '#333',
    },
    detail: {
        fontSize: '1rem',
        margin: '5px 0',
        color: '#555',
    },
    inquiringUsersSection: {
        marginTop: '30px',
    },
    sectionTitle: {
        fontSize: '1.8rem',
        marginBottom: '20px',
    },
    inquiringUsersList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
    },
    userCard: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        width: '250px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    button: {
        marginTop: '10px',
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
};

export default ViewProfile;
