import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        { imgSrc: "/assets/images/peaceful_muslim_couple_financial_disagreement.png", time: "December 8th, 2024", title: "How Can Couples Resolve Financial Disagreements in Sunni Islam", content: "Discover effective ways for Sunni couples to resolve financial disagreements according to Islamic principles.", link: "/how-can-couples-resolve-financial-disagreements-in-sunni-islam" },
        { imgSrc: "/assets/images/muslim_couple_barakah.png", time: "December 8th, 2024", title: "Barakah in Sunni Marriage", content: "Understand the significance of Barakah in a Sunni marriage and how couples can seek it.", link: "/barakah-in-sunni-marriage" },
        { imgSrc: "/assets/images/muslim_family_sharing.png", time: "December 8th, 2024", title: "Role of Sharing in Sunni Household", content: "Explore the role of sharing in building love and compassion within Sunni households.", link: "/role-of-sharing-in-sunni-household" },
        { imgSrc: "/assets/images/how_should_couples_address_misunderstandings_in_sunni_islam.png", time: "December 8th, 2024", title: "How Should Couples Address Misunderstandings in Sunni Islam", content: "Learn the best ways for Sunni couples to address misunderstandings within marriage.", link: "/how-should-couples-address-misunderstandings-in-sunni-islam" },
        { imgSrc: "/assets/images/prophet_teachings_on_marriage.png", time: "December 8th, 2024", title: "Prophet's Teachings About Marriage", content: "Discover the profound teachings of Prophet Muhammad (PBUH) about marriage in Islam.", link: "/prophet-teachings-about-marriage" },
        { imgSrc: "/assets/images/sunni_in_law_relationship.png", time: "December 8th, 2024", title: "How Are In-Laws Treated in Sunni Marriage", content: "Learn how in-laws are treated and respected within a Sunni Islamic marriage.", link: "/how-are-in-laws-treated-in-sunni-marriage" },
        { imgSrc: "/assets/images/good_looking_muslim_couple_new.png", time: "December 8th, 2024", title: "Acts of Kindness Between Spouses in Sunni Islam", content: "Discover how small acts of kindness can strengthen the bond between spouses.", link: "/acts-of-kindness-between-spouses-in-sunni-islam" },
        { imgSrc: "/assets/images/muslim_couple_learning_together.png", time: "December 8th, 2024", title: "How Knowledge Is Encouraged in Sunni Marriages", content: "Learn how seeking knowledge together strengthens marriage in Sunni Islam.", link: "/how-knowledge-is-encouraged-in-sunni-marriages" },
        { imgSrc: "/assets/images/how_should_couples_address_misunderstandings_in_sunni_islam.png", time: "December 8th, 2024", title: "How Should Couples Address Misunderstandings in Sunni Islam", content: "A guide for Sunni couples on how to approach and resolve misunderstandings.", link: "/how-should-couples-address-misunderstandings-in-sunni-islam" },
        { imgSrc: "/assets/images/beautiful_sunni_couple.png", time: "December 8th, 2024", title: "How Sunni Muslims Should Handle Life Transitions Within Marriage", content: "Discover how Sunni couples can navigate life transitions and maintain marital harmony.", link: "/how-sunni-muslims-should-handle-life-transitions-within-marriage" },
        { imgSrc: "/assets/images/sunni_marital_happiness_couple.png", time: "December 8th, 2024", title: "How Is Marital Happiness Achieved in Sunni Muslim Teachings", content: "Learn the key Islamic principles for achieving happiness in Sunni marriage.", link: "/how-is-marital-happiness-achieved-in-sunni-muslim-teachings" },
        { imgSrc: "/assets/images/marital_harmony_sunni_islam.png", time: "December 8th, 2024", title: "Key Principles for Marital Harmony in Sunni Islam", content: "Discover essential principles for building marital harmony in Sunni Islam.", link: "/key-principles-for-marital-harmony-in-sunni-islam" },
        { imgSrc: "/assets/images/muslim_couple_smiling_together.png", time: "December 8th, 2024", title: "How Islam Encourages Empathy Between Spouses", content: "Explore the role of empathy in strengthening marital bonds in Islam.", link: "/how-islam-encourages-empathy-between-spouses" },
        { imgSrc: "/assets/images/empathy_in_marriage.png", time: "December 8th, 2024", title: "How Does Islam Encourage Empathy Between Spouses", content: "Discover how empathy between spouses fosters love and understanding in marriage.", link: "/how-does-islam-encourage-empathy-between-spouses" },
        { imgSrc: "/assets/images/sunni_marriage_loyalty_concept.png", time: "December 8th, 2024", title: "Loyalty in Sunni Marriages", content: "Explore the role of loyalty and how it strengthens marriages in Sunni Islam.", link: "/loyalty-in-sunni-marriages" },
        { imgSrc: "/assets/images/sunni_husband_wife_support.png", time: "December 8th, 2024", title: "How Husband Can Support Wife's Religious Goals", content: "Learn how husbands can support their wives' religious aspirations in marriage.", link: "/how-husband-can-support-wifes-religious-goals" },
        { imgSrc: "/assets/images/sunni_marriage_trust_development.png", time: "December 8th, 2024", title: "How Does Trust Develop Over Time in Sunni Marriages", content: "Discover how trust develops and strengthens in Sunni Islamic marriages.", link: "/how-does-trust-develop-over-time-in-sunni-marriages" },
        { imgSrc: "/assets/images/how_couples_preserve_islamic_values.png", time: "December 8th, 2024", title: "How Couples Preserve Islamic Values", content: "Learn how couples maintain Islamic values in their marriage and family life.", link: "/how-couples-preserve-islamic-values" },
        { imgSrc: "/assets/images/muslim_couple_encouraging_faith.png", time: "December 8th, 2024", title: "How Can a Wife Encourage Her Husband's Faith", content: "Explore how a wife can help nurture her husband's faith in Islam.", link: "/how-can-a-wife-encourage-her-husbands-faith" },
        { imgSrc: "/assets/images/beautiful_sunni_couple.png", time: "December 8th, 2024", title: "How Couples Approach Major Family Decisions in Sunni Islam", content: "Learn how couples make family decisions together while following Islamic principles.", link: "/how-couples-approach-major-family-decisions-in-sunni-islam" },
        { imgSrc: "/assets/images/peaceful_harmony_couple.png", time: "December 8th, 2024", title: "How Kindness Fosters Harmony in Sunni Marriage", content: "Discover how kindness can foster love and harmony in a Sunni marriage.", link: "/how-kindness-fosters-harmony-in-sunni-marriage" },
        { imgSrc: "/assets/images/sunni_muslim_marriage_image.png", time: "December 8th, 2024", title: "How Does Modesty Affect Sunni Muslim Marital Life", content: "Learn how modesty impacts marital life in Sunni Islam.", link: "/how-does-modesty-affect-sunni-muslim-marital-life" },
        { imgSrc: "/assets/images/romantic_expressions_halal_muslim_couple.png", time: "December 8th, 2024", title: "Romantic Expressions and Halal Boundaries", content: "Explore halal boundaries for romantic expressions between spouses in Islam.", link: "/romantic-expressions-halal-boundaries" },
        { imgSrc: "/assets/images/muslim_family_stress.png", time: "December 8th, 2024", title: "How Should Muslim Family Deal With Life Stressors", content: "Learn how Muslim families can cope with life's stressors through Islamic guidance.", link: "/how-should-muslim-family-deal-with-life-stressors" },
        { imgSrc: "/assets/images/parenting_islamic_values_couple.png", time: "December 8th, 2024", title: "How Parents Can Model Islamic Values in Their Marriage", content: "Discover how parents can model Islamic values for their children through marriage.", link: "/how-parents-can-model-islamic-values-in-their-marriage" },
        { imgSrc: "/assets/images/faithful_muslim_couple.png", time: "December 8th, 2024", title: "How Can Sunni Couples Stay Focused on Shared Goals", content: "Discover how Sunni couples stay focused on shared goals to build a strong marriage.", link: "/how-can-sunni-couples-stay-focused-on-shared-goals" },
        { imgSrc: "/assets/images/healthy_marriage_muslim_couple.png", time: "December 8th, 2024", title: "How Islamic Teaching Supports Healthy Marriage Habits", content: "Learn how Islamic teachings promote healthy marriage habits for lifelong harmony.", link: "/how-islamic-teaching-supports-healthy-marriage-habits" },
        { imgSrc: "/assets/images/financial_couple.png", time: "December 8th, 2024", title: "How Should Couples Manage Finances According to Islamic Teachings", content: "Explore Islamic principles on managing finances in a marriage.", link: "/how-should-couples-manage-finances-according-to-islamic-teachings" },
        { imgSrc: "/assets/images/gratitude_in_sunni_islam.png", time: "December 8th, 2024", title: "Gratitude in Sunni Islam", content: "Discover the role of gratitude in strengthening marriages and family ties.", link: "/gratitude-in-sunni-islam" },
        { imgSrc: "/assets/images/kindness_in_parenting_islam.png", time: "December 8th, 2024", title: "Role of Kindness in Raising Children in Islam", content: "Learn how kindness shapes the upbringing of children in a Muslim household.", link: "/role-of-kindness-in-raising-children-in-islam" },
        { imgSrc: "/assets/images/mother_role_in_sunni_marriage.png", time: "December 8th, 2024", title: "How Mother's Role is Emphasized in Sunni Marriages", content: "Explore the vital role mothers play in shaping Sunni marriages.", link: "/how-mothers-role-is-emphasized-in-sunni-marriages" },
        { imgSrc: "/assets/images/ideal_muslim_couple.png", time: "December 8th, 2024", title: "Quran Teaching About Treating Spouse Well", content: "Learn about Quranic guidance on how spouses should treat each other.", link: "/quran-teaching-about-treating-spouse-well" },
        { imgSrc: "/assets/images/grandparents_in_sunni_family.png", time: "December 8th, 2024", title: "Role of Grandparents in Sunni Families", content: "Discover how grandparents influence family life and offer valuable wisdom.", link: "/role-of-grandparents-in-sunni-families" },
        { imgSrc: "/assets/images/loyal_muslim_couple.png", time: "December 8th, 2024", title: "How Loyalty is Defined in Sunni Marriages", content: "Learn how loyalty is established and valued in Sunni Islamic marriages.", link: "/how-loyalty-is-defined-in-sunni-marriages" },
        { imgSrc: "/assets/images/respectful_muslim_couple.png", time: "December 8th, 2024", title: "Islamic Principles of Mutual Respect in Marriage", content: "Understand how respect strengthens marriage according to Islamic teachings.", link: "/islamic-principles-mutual-respect-marriage" },
        { imgSrc: "/assets/images/consultation_in_sunni_marriage.png", time: "December 8th, 2024", title: "Consultation in Sunni Marriage", content: "Discover the importance of Shura (consultation) in Sunni marriages.", link: "/consultation-in-sunni-marriage" },
        { imgSrc: "/assets/images/financial_transparency_in_islam.png", time: "December 8th, 2024", title: "How Can Families Ensure Financial Transparency in Islam", content: "Learn how to promote financial transparency in a Muslim family.", link: "/how-can-families-ensure-financial-transparency-in-islam" },
        { imgSrc: "/assets/images/muslim_couple_balancing_responsibilities.png", time: "December 8th, 2024", title: "How Muslim Couples Balance Responsibilities", content: "Explore how Muslim couples balance personal and family responsibilities.", link: "/how-muslim-couples-balance-responsibilities" },
        { imgSrc: "/assets/images/patient_muslim_couple.png", time: "December 8th, 2024", title: "Patience in Halal Marriage", content: "Discover the value of patience in halal marriages according to Islamic teachings.", link: "/patience-in-halal-marriage" },
        { imgSrc: "/assets/images/love_and_respect_muslim_couple.png", time: "December 8th, 2024", title: "How Mutual Love is Expressed in Sunni Muslim Community", content: "Learn how love and respect are expressed in Sunni Muslim marriages.", link: "/how-mutual-love-expressed-in-sunni-muslim-community" },
        { imgSrc: "/assets/images/understanding_in_marriage.png", time: "December 8th, 2024", title: "How Sunni Muslims Can Prevent Misunderstandings in Marriage", content: "Discover effective ways to avoid misunderstandings in Sunni Islamic marriages.", link: "/how-sunni-muslims-can-prevent-misunderstandings-in-marriage" },
        { imgSrc: "/assets/images/healthy_family_conflict_resolution.png", time: "December 8th, 2024", title: "Healthy Ways to Address Family Conflicts", content: "Explore how to resolve family conflicts in a healthy, respectful manner.", link: "/healthy-ways-to-address-family-conflicts" },
        { imgSrc: "/assets/images/sunni_marriage_compassion.png", time: "December 8th, 2024", title: "Role of Compassion in Sunni Marriage", content: "Learn how compassion strengthens marital bonds in Sunni marriages.", link: "/role-of-compassion-in-sunni-marriage" },
        { imgSrc: "/assets/images/celebrating_milestones_in_islam_couple.png", time: "December 8th, 2024", title: "How Should a Husband and Wife Celebrate Milestones in Islam", content: "Discover how Islamic principles encourage milestone celebrations.", link: "/how-should-a-husband-and-wife-celebrate-milestones-in-islam" },
        { imgSrc: "/assets/images/rights_of_children_in_islamic_marriage.png", time: "December 8th, 2024", title: "Children's Rights in Sunni Islamic Marriage", content: "Understand the rights of children in a Sunni Islamic marriage.", link: "/childrens-rights-sunni-islamic-marriage" },
        { imgSrc: "/assets/images/appreciative_muslim_couple.png", time: "December 8th, 2024", title: "How Sunni Spouses Express Appreciation", content: "Learn how Sunni spouses show appreciation to strengthen marriage bonds.", link: "/how-sunni-spouses-express-appreciation" },
        { imgSrc: "/assets/images/healthy_marital_relationship_islam.png", time: "December 8th, 2024", title: "Healthy Ways to Address Marital Challenges", content: "Explore strategies to overcome marital challenges according to Islam.", link: "/healthy-ways-to-address-marital-challenges" },
        { imgSrc: "/assets/images/role_of_kindness_in_raising_children.png", time: "December 8th, 2024", title: "Role of Kindness in Raising Children", content: "Discover how kindness positively influences children's development.", link: "/role-of-kindness-in-raising-children" },
        { imgSrc: "/assets/images/significance_of_community_support_in_marriage.png", time: "December 8th, 2024", title: "Role of Kindness in Raising Children", content: "Learn how community support impacts a family's growth and success.", link: "/role-of-kindness-in-raising-children" },
        { imgSrc: "/assets/images/focused_muslim_couple.png", time: "December 8th, 2024", title: "Significance of Community Support in Marriage", content: "Discover the role of community support in strengthening Muslim marriages.", link: "/significance-of-community-support-in-marriage" },
        { imgSrc: "/assets/images/healthy_muslim_couple.png", time: "December 8th, 2024", title: "How Sunni Couples Stay Focused on Goals", content: "Learn how Sunni couples maintain focus on shared marital goals.", link: "/how-sunni-couples-stay-focused-on-goals" },
        { imgSrc: "/assets/images/grandparents_role_in_muslim_household.png", time: "December 8th, 2024", title: "Grandparents' Role in Muslim Household", content: "Discover how grandparents contribute to Muslim family dynamics.", link: "/grandparents-role-in-muslim-household" },
        { imgSrc: "/assets/images/strong_muslim_couple_together.png", time: "December 8th, 2024", title: "How Muslim Spouses Manage Stress Together in Marriage", content: "Discover how Muslim spouses support each other and manage stress together in marriage.", link: "/how-muslim-spouses-manage-stress-together-in-marriage" },
        { imgSrc: "/assets/images/unique_muslim_couple_image.png", time: "December 8th, 2024", title: "Avoid Conflicts in Marriage Islam", content: "Learn how to avoid conflicts and maintain harmony in marriage according to Islamic teachings.", link: "/avoid-conflicts-in-marriage-islam" }
    ];
  

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog7" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8" className="active">8</Link></li>
                            <li><Link to="../blog9">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog9" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
