import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        { imgSrc: "/assets/images/muslim_couple_finance_management.png", time: "December 8th, 2024", title: "How Should Couples Manage Finances in Islam", content: "Learn how couples can effectively manage finances according to Islamic principles.", link: "/how-should-couples-manage-finances-in-islam" },
        { imgSrc: "/assets/images/muslim_couple_with_mother.png", time: "December 8th, 2024", title: "How is a Mother's Role Emphasized in Sunni Marriages", content: "Discover the important role mothers play in the success of Sunni marriages.", link: "/how-is-a-mothers-role-emphasized-in-sunni-marriages" },
        { imgSrc: "/assets/images/faithful_spouses_together.png", time: "December 8th, 2024", title: "What Does the Quran Say About Treating Spouses Well", content: "Explore Quranic teachings on treating spouses with kindness and respect.", link: "/what-does-the-quran-say-about-treating-spouses-well" },
        { imgSrc: "/assets/images/muslim_family_values.png", time: "December 8th, 2024", title: "How Parents Uphold Islamic Values in Front of Children", content: "Learn how parents model Islamic values in front of their children.", link: "/how-parents-uphold-islamic-values-in-front-of-children" },
        { imgSrc: "/assets/images/muslim_family_values.png", time: "December 8th, 2024", title: "Family Fostering Patience", content: "Discover how patience is nurtured within Islamic family environments.", link: "/family-fostering-patience" },
        { imgSrc: "/assets/images/grandparents_sunni_family.png", time: "December 8th, 2024", title: "Role of Grandparents in Sunni Families", content: "Uncover the significant role grandparents play in Sunni family life.", link: "/role-of-grandparents-in-sunni-families" },
        { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "Why Marriage is Good in Islam", content: "Explore why marriage holds a pivotal role in Islam and its benefits.", link: "/why-marriage-is-good-in-islam" },
        { imgSrc: "/assets/images/sunni_marriage_loyalty_image.png", time: "December 8th, 2024", title: "Loyalty in Sunni Marriages", content: "Discover how loyalty is prioritized in Sunni marriages.", link: "/loyalty-in-sunni-marriages" },
        { imgSrc: "/assets/images/consultation_in_sunni_marriage.png", time: "December 8th, 2024", title: "Consultation in Sunni Marriage", content: "Understand the role of consultation in ensuring a harmonious marriage.", link: "/consultation-in-sunni-marriage" },
        { imgSrc: "/assets/images/mutual_respect_muslim_couple.png", time: "December 8th, 2024", title: "Islamic Principles for Mutual Respect in Marriage", content: "Learn how mutual respect is maintained in Islamic marriages.", link: "/islamic-principles-for-mutual-respect-in-marriage" },
        { imgSrc: "/assets/images/financial_transparency_in_islam.png", time: "December 8th, 2024", title: "Financial Transparency in Islam", content: "Explore how financial transparency is maintained in Islamic households.", link: "/financial-transparency-in-islam" },
        { imgSrc: "/assets/images/good_looking_muslim_couple_1.png", time: "December 8th, 2024", title: "How Muslim Couples Balance Responsibilities", content: "Learn how Muslim couples share and balance responsibilities in family life.", link: "/how-muslim-couple-balance-responsibilities" },
        { imgSrc: "/assets/images/patient_muslim_couple.png", time: "December 8th, 2024", title: "How is Patience Emphasized in Halal Marriage", content: "Discover how patience is emphasized as a key to a successful marriage.", link: "/how-is-patience-emphasized-in-halal-marriage" },
        { imgSrc: "/assets/images/sunni_muslim_couple_love.png", time: "December 8th, 2024", title: "How is Mutual Love Expressed in Sunni Muslim Community", content: "Uncover how love is expressed within Sunni Muslim marriages.", link: "/how-is-mutual-love-expressed-in-sunni-muslim-community" },
        { imgSrc: "/assets/images/understanding_sunni_marriage.png", time: "December 8th, 2024", title: "How Sunni Muslims Prevent Misunderstandings in Marriage", content: "Learn how to prevent misunderstandings in Sunni Muslim marriages.", link: "/how-sunni-muslims-prevent-misunderstandings-in-marriage" },
        { imgSrc: "/assets/images/sunni_marriage_compassion.png", time: "December 8th, 2024", title: "Role of Compassion in Sunni Marriage", content: "Understand the essential role of compassion in successful marriages.", link: "/role-of-compassion-in-sunni-marriage" },
        { imgSrc: "/assets/images/celebrating_milestones_in_islam.png", time: "December 8th, 2024", title: "How Should a Husband and Wife Celebrate Milestones in Islam", content: "Explore the proper way to celebrate important milestones as a couple.", link: "/how-should-a-husband-and-wife-celebrate-milestones-in-islam" },
        { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "How Role of Sadaqah is Practiced in Marriage", content: "Discover how the practice of Sadaqah strengthens marital bonds.", link: "/how-role-of-sadaqah-practiced-in-marriage" },
        { imgSrc: "/assets/images/halal_marriage_couple.png", time: "December 8th, 2024", title: "How Can Couples Ensure Their Marriage Stays Halal", content: "Learn the key steps to ensure a marriage remains Halal.", link: "/how-can-couples-ensure-their-marriage-stays-halal" },
        { imgSrc: "/assets/images/faithful_sunni_couple.png", time: "December 8th, 2024", title: "How is Marital Harmony Prioritized in Sunni Families", content: "Explore how marital harmony is fostered within Sunni families.", link: "/how-is-marital-harmony-prioritized-in-sunni-families" },
        { imgSrc: "/assets/images/muslim_couple_resolving_misunderstanding.png", time: "December 8th, 2024", title: "How are Misunderstandings Resolved in Sunni Islam", content: "Learn how misunderstandings are resolved in Sunni Muslim marriages.", link: "/how-are-misunderstandings-resolved-in-sunni-islam" },
        { imgSrc: "/assets/images/individual_goals_sunni_marriage.png", time: "December 8th, 2024", title: "Individual Goals in Sunni Marriage", content: "Discover how individual goals are approached in a Sunni marriage.", link: "/individual-goals-in-sunni-marriage" },
        { imgSrc: "/assets/images/forgiveness_in_sunni_marriage.png", time: "December 8th, 2024", title: "Role of Forgiveness in Sunni Marriage", content: "Learn the importance of forgiveness in strengthening marital ties.", link: "/role-of-forgiveness-in-sunni-marriage" },
        { imgSrc: "/assets/images/establishing_islamic_boundaries_family.png", time: "December 8th, 2024", title: "How Islamic Boundaries are Established in Family Life", content: "Understand the importance of boundaries in Islamic family life.", link: "/how-islamic-boundaries-established-family-life" },
        { imgSrc: "/assets/images/prophetic_marital_guidance.png", time: "December 8th, 2024", title: "Prophet's Example of Marital Life", content: "Discover the Prophet's teachings on maintaining a successful marriage.", link: "/prophets-example-marital-life" },
        { imgSrc: "/assets/images/muslim_couple_expressing_affection.png", time: "December 8th, 2024", title: "How to Express Affection in Marriage", content: "Learn how affection is expressed in a Halal manner.", link: "/how-to-express-affection-in-marriage" },
        { imgSrc: "/assets/images/upholding_religious_values_family.png", time: "December 8th, 2024", title: "Upholding Religious Values in Family", content: "Discover how religious values are upheld in Islamic families.", link: "/uphold-religious-values-family" },
        { imgSrc: "/assets/images/nurturing_childs_marriage.png", time: "December 8th, 2024", title: "Role of Parents in Nurturing Marriage", content: "Discover the role of parents in nurturing and supporting their child's marriage.", link: "/role-of-parents-in-nurturing-marriage" },
        { imgSrc: "/assets/images/understanding_sunni_marriage_couple.png", time: "December 8th, 2024", title: "How Can Couples Prevent Misunderstandings in Sunni Islam", content: "Learn strategies for preventing misunderstandings in Sunni Muslim marriages.", link: "/how-can-couples-prevent-misunderstandings-in-sunni-islam" },
        { imgSrc: "/assets/images/respect_between_inlaws_muslim_couple.png", time: "December 8th, 2024", title: "How Islam Encourages Respect Between In-Laws", content: "Understand how Islam encourages respectful relationships between in-laws.", link: "/how-islam-encourages-respect-between-in-laws" },
        { imgSrc: "/assets/images/sunni_marriage_understanding_couple.png", time: "December 8th, 2024", title: "How Mutual Understanding is Achieved in Sunni Marriage", content: "Discover the essential steps to achieve mutual understanding in a Sunni marriage.", link: "/how-mutual-understanding-is-achieved-in-sunni-marriage" },
        { imgSrc: "/assets/images/respect_between_families_muslim_couple.png", time: "December 8th, 2024", title: "Islamic Etiquettes for Respect Between Families", content: "Explore the etiquettes that promote respect between families in Islam.", link: "/islamic-etiquettes-respect-between-families" },
        { imgSrc: "/assets/images/muslim_couple_shared_property.png", time: "December 8th, 2024", title: "How Does Islamic Law View Shared Property in Marriage", content: "Learn how Islamic law governs shared property within a marriage.", link: "/how-does-islamic-law-view-shared-property-in-marriage" },
        { imgSrc: "/assets/images/family_boundaries_respect_image.png", time: "December 8th, 2024", title: "How Should Boundaries Be Respected Within Family Gatherings", content: "Discover the significance of respecting boundaries during family gatherings.", link: "/how-should-boundaries-be-respected-within-family-gatherings" },
        { imgSrc: "/assets/images/marital_harmony_in_islam.png", time: "December 8th, 2024", title: "How Marital Harmony is Maintained in Islam", content: "Explore the key principles for maintaining harmony in Islamic marriages.", link: "/how-marital-harmony-is-maintained-in-islam" },
        { imgSrc: "/assets/images/muslim_marriage_harmony.png", time: "December 8th, 2024", title: "How Marital Harmony is Maintained in Islam", content: "Learn how to maintain marital harmony according to Islamic teachings.", link: "/how-marital-harmony-maintained-in-islam" },
        { imgSrc: "/assets/images/muslim_couple_praying_together.png", time: "December 8th, 2024", title: "How Does Shared Prayer Benefit Sunni Couples", content: "Discover the spiritual benefits of couples praying together in Sunni Islam.", link: "/how-does-shared-prayer-benefit-sunni-couples" },
        { imgSrc: "/assets/images/family_boundaries_respect_image.png", time: "December 8th, 2024", title: "How Should Boundaries Be Respected Within Family Gatherings", content: "Learn how respecting boundaries during family gatherings strengthens relationships.", link: "/how-should-boundaries-be-respected-within-family-gatherings" },
        { imgSrc: "/assets/images/patience_in_marriage.png", time: "December 8th, 2024", title: "How Patience Builds Strong Marriages", content: "Explore how patience serves as a foundation for a successful marriage.", link: "/how-patience-builds-strong-marriages" },
        { imgSrc: "/assets/images/muslim_couple_in_conflict.png", time: "December 8th, 2024", title: "How Should Conflicts Be Addressed Privately", content: "Learn how private conflict resolution can preserve the dignity of a marriage.", link: "/how-should-conflicts-be-addressed-privately" },
        { imgSrc: "/assets/images/family_fostering_islamic_habits.png", time: "December 8th, 2024", title: "How Can Family Foster Good Islamic Habits", content: "Discover how families encourage the practice of Islamic habits.", link: "/how-can-family-foster-good-islamic-habits" },
        { imgSrc: "/assets/images/family_boundaries_respect_image.png", time: "December 8th, 2024", title: "How Should Boundaries Be Respected Within Family Gatherings", content: "Uncover the importance of respecting boundaries in family gatherings.", link: "/how-should-boundaries-be-respected-within-family-gatherings" },
        { imgSrc: "/assets/images/sunni_family_obligations_image.png", time: "December 8th, 2024", title: "How Should Sunnis Manage Family Obligations", content: "Learn how Sunnis approach family obligations and responsibilities.", link: "/how-should-sunnis-manage-family-obligations" },
        { imgSrc: "/assets/images/muslim_couple_gratitude.png", time: "December 8th, 2024", title: "How Does a Muslim Couple Show Gratitude in Marriage", content: "Discover the significance of gratitude in Islamic marital relationships.", link: "/how-does-a-muslim-couple-show-gratitude-in-marriage" },
        { imgSrc: "/assets/images/joyful_sunni_family_eid_celebration.png", time: "December 8th, 2024", title: "How Eid Celebrations Are Observed in Sunni Families", content: "Learn how Eid celebrations are enjoyed within Sunni Muslim families.", link: "/how-eid-celebrations-are-observed-in-sunni-families" },
        { imgSrc: "/assets/images/successful_muslim_couple.png", time: "December 8th, 2024", title: "Islamic Principles for Celebrating Success", content: "Explore how Islam encourages families to celebrate success in a Halal way.", link: "/islamic-principles-for-celebrating-success" },
        { imgSrc: "/assets/images/family_gathering_muslim_couple.png", time: "December 8th, 2024", title: "Family Gatherings and Islam", content: "Uncover the role of family gatherings in Islam and how they strengthen relationships.", link: "/family-gatherings-and-islam" },
        { imgSrc: "/assets/images/family_boundaries_respect_image.png", time: "December 8th, 2024", title: "How Should Boundaries Be Respected Within Family Gatherings", content: "Learn how respecting boundaries in family gatherings promotes family unity.", link: "/how-should-boundaries-be-respected-within-family-gatherings" },
        { imgSrc: "/assets/images/charity_in_sunni_marriage.png", time: "December 8th, 2024", title: "How Charity Impacts Sunni Marriages", content: "Discover how charity strengthens the bonds of marriage in Sunni Islam.", link: "/how-charity-impacts-sunni-marriages" },
        { imgSrc: "/assets/images/celebrating_islamic_family.png", time: "December 8th, 2024", title: "How Are Islamic Celebrations Shared With Family", content: "Explore how Islamic celebrations are shared with loved ones in the family.", link: "/how-are-islamic-celebrations-shared-with-family" },
        { imgSrc: "/assets/images/beautiful_muslim_couple_holding_hands.png", time: "December 8th, 2024", title: "How Love and Mercy Are Shown Between Spouses", content: "Learn the significance of love and mercy in a successful marriage.", link: "/how-love-and-mercy-are-shown-between-spouses" },
        { imgSrc: "/assets/images/understanding_in_marriage_islam.png", time: "December 8th, 2024", title: "How Does a Couple Address Misunderstandings in Islam", content: "Discover how couples can resolve misunderstandings in a Halal way.", link: "/how-does-a-couple-address-misunderstandings-in-islam" },
        { imgSrc: "/assets/images/peaceful_muslim_home.png", time: "December 8th, 2024", title: "How is the Home Viewed as a Peaceful Place in Islam", content: "Learn how the home is regarded as a sanctuary in Islamic teachings.", link: "/how-is-the-home-viewed-as-a-peaceful-place-in-islam" },
    
        { imgSrc: "/assets/images/beautiful_muslim_family.png", time: "December 8th, 2024", title: "How is Privacy Upheld in a Muslim Family", content: "Discover how privacy is preserved within a Muslim family according to Islamic teachings.", link: "/how-is-privacy-upheld-in-a-muslim-family" },
        { imgSrc: "/assets/images/financial_planning_in_islam.png", time: "December 8th, 2024", title: "How Does Family Manage Financial Planning in Islam", content: "Explore how Islamic families approach financial planning and resource management.", link: "/how-does-family-manage-financial-planning-in-islam" },
        { imgSrc: "/assets/images/align_goals_muslim_couple.png", time: "December 8th, 2024", title: "Aligning Goals with Islamic Principles", content: "Learn how couples align their family goals with Islamic values.", link: "/aligning-goals-with-islamic-principles" },
        { imgSrc: "/assets/images/muslim_couple_in_law_relationship.png", time: "December 8th, 2024", title: "In-Law Relationships in Islam", content: "Understand the principles for building healthy relationships with in-laws in Islam.", link: "/in-law-relationships-in-islam" },
        { imgSrc: "/assets/images/mutual_love_in_islamic_household.png", time: "December 8th, 2024", title: "Mutual Love in Islamic Household", content: "Discover how mutual love strengthens relationships within Islamic households.", link: "/mutual-love-in-islamic-household" },
        { imgSrc: "/assets/images/strength_in_family_love.png", time: "December 8th, 2024", title: "How Family Members Can Uplift Each Other", content: "Learn how family members can uplift one another and foster love and unity.", link: "/how-family-members-can-uplift-each-other" },
        { imgSrc: "/assets/images/husband_role_in_family_leadership.png", time: "December 8th, 2024", title: "How Husband's Role is Emphasized in Family Leadership", content: "Explore the husband's role as a leader within the family according to Islam.", link: "/how-husbands-role-is-emphasized-in-family-leadership" },
        { imgSrc: "/assets/images/faithful_wife_supporting_family.png", time: "December 8th, 2024", title: "How Should a Wife Support Her Family in Islam", content: "Discover the role of a wife in supporting her family based on Islamic teachings.", link: "/how-should-a-wife-support-her-family-in-islam" },
        { imgSrc: "/assets/images/understanding_between_married_couples.png", time: "December 8th, 2024", title: "How Understanding is Fostered Between Married Couples", content: "Learn how understanding is cultivated between couples for a harmonious marriage.", link: "/how-understanding-is-fostered-between-married-couples" },
        { imgSrc: "/assets/images/good_looking_muslim_couple.png", time: "December 8th, 2024", title: "Role of Sadaqah in Marriage Life", content: "Explore how the concept of Sadaqah plays a role in strengthening marital bonds.", link: "/role-of-sadaqah-in-marriage-life" },
        { imgSrc: "/assets/images/spiritual_muslim_couple.png", time: "December 8th, 2024", title: "How Spouses Prioritize Religious Obligations", content: "Discover how spouses prioritize their religious obligations to strengthen their marriage.", link: "/how-spouses-prioritize-religious-obligations" },
        { imgSrc: "/assets/images/financial_independence_in_islam.png", time: "December 8th, 2024", title: "Financial Independence in Islam", content: "Learn how Islam encourages financial independence and its significance in family life.", link: "/financial-independence-in-islam" },
        { imgSrc: "/assets/images/charity_emphasis_in_family.png", time: "December 8th, 2024", title: "How Charity is Emphasized in the Family", content: "Explore the role of charity in fostering family unity and spiritual growth.", link: "/how-charity-is-emphasized-in-the-family" },
        { imgSrc: "/assets/images/patience_in_disagreements.png", time: "December 8th, 2024", title: "Importance of Patience in Disagreements", content: "Learn how patience in disagreements leads to peaceful family relationships.", link: "/importance-of-patience-in-disagreements" },
        { imgSrc: "/assets/images/family_traditions_in_islam.png", time: "December 8th, 2024", title: "Family Traditions in Islam", content: "Discover the importance of preserving family traditions within an Islamic context.", link: "/family-traditions-in-islam" },
        { imgSrc: "/assets/images/respectful_family_heritage.png", time: "December 8th, 2024", title: "How to Honor Family Heritage in Islam", content: "Learn how to honor and respect family heritage in Islamic households.", link: "/how-to-honor-family-heritage-in-islam" },
        { imgSrc: "/assets/images/good_looking_muslim_couple_new.png", time: "December 8th, 2024", title: "How Should Sunni Parents Counsel Their Children on Marriage", content: "Discover how Sunni parents should guide their children on marriage.", link: "/how-should-sunni-parents-counsel-their-children-on-marriage" },
        { imgSrc: "/assets/images/parents_showing_appreciation.png", time: "December 8th, 2024", title: "How Can Children Show Appreciation for Their Parents", content: "Learn how children can express appreciation for their parents according to Islam.", link: "/how-can-children-show-appreciation-for-their-parents" },
        { imgSrc: "/assets/images/nikah_tradition_muslim_couple.png", time: "December 8th, 2024", title: "How Marriage Rituals Honor Religious Traditions", content: "Explore the significance of marriage rituals in honoring religious traditions.", link: "/how-marriage-rituals-honor-religious-traditions" },
        { imgSrc: "/assets/images/family_milestone_preparation.png", time: "December 8th, 2024", title: "How Does a Family Prepare for Major Milestones", content: "Learn how families prepare for important milestones and life events.", link: "/how-does-a-family-prepare-for-major-milestones" },
        { imgSrc: "/assets/images/protecting_family_traditions_muslim_couple.png", time: "December 8th, 2024", title: "How Can a Husband Protect Family Traditions", content: "Discover how a husband can safeguard family traditions within an Islamic household.", link: "/how-can-a-husband-protect-family-traditions" },
        { imgSrc: "/assets/images/raising_honest_children.png", time: "December 8th, 2024", title: "How is Honesty Emphasized in Raising Children", content: "Learn how honesty is instilled in children according to Islamic principles.", link: "/how-is-honesty-emphasized-in-raising-children" },
        { imgSrc: "/assets/images/sibling_family_bond.png", time: "December 8th, 2024", title: "How Can Siblings Strengthen Family Bonds", content: "Explore how siblings strengthen family bonds through shared experiences.", link: "/how-can-siblings-strengthen-family-bonds" },
        { imgSrc: "/assets/images/halal_family_gathering.png", time: "December 8th, 2024", title: "How Are Halal Practices Maintained in Family Gatherings", content: "Discover how families maintain Halal practices during gatherings.", link: "/how-are-halal-practices-maintained-in-family-gatherings" },
        { imgSrc: "/assets/images/family_growth_balance.png", time: "December 8th, 2024", title: "How to Balance Family Obligations with Personal Growth", content: "Learn how to balance family obligations with personal development and growth.", link: "/how-to-balance-family-obligations-with-personal-growth" },
        { imgSrc: "/assets/images/muslim_family_praying_together.png", time: "December 8th, 2024", title: "How Prayer Strengthens Family Unity", content: "Discover the role of prayer in uniting families and fostering spiritual growth.", link: "/how-prayer-strengthens-family-unity" },
        { imgSrc: "/assets/images/supporting_family_goals.png", time: "December 8th, 2024", title: "How Family Members Support Goals", content: "Explore how family members support each other's goals for collective success.", link: "/how-family-members-support-goals" },
        { imgSrc: "/assets/images/how_to_practice_patience_during_family_challenges.png", time: "December 8th, 2024", title: "How to Practice Patience During Family Challenges", content: "Discover how patience can help families overcome challenges and foster unity.", link: "/how-to-practice-patience-during-family-challenges" },
        { imgSrc: "/assets/images/teaching_islamic_values_in_action.png", time: "December 8th, 2024", title: "How Islamic Values are Taught in Everyday Actions", content: "Learn how Islamic values are instilled through daily actions and family life.", link: "/how-islamic-values-are-taught-in-everyday-actions" }
    ];
        
    
    
    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog8" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog7">7</Link></li>
                            <li><Link to="../blog8">8</Link></li>
                            <li><Link to="../blog9" className="active">9</Link></li>
                            <li><Link to="../blog10">10</Link></li>
                            <li><Link to="../blog10" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
