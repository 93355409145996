import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Messageicon from "../../assets/images/contact_message_icon.png";
import Mailicon from "../../assets/images/mail_icon.png";
import Callicon from "../../assets/images/call_icon.png";
import Locationicon from "../../assets/images/location_icon.png";

function Leave() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country: '',
        phone: '',
        message: '',
        agree: false
    });

    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionDisabled, setSubmissionDisabled] = useState(false);
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent multiple submissions
        if (submissionDisabled) return;

        setIsSubmitting(true);
        setSubmissionDisabled(true);

        try {
            const response = await axios.post(apiEndpoint, {
                name: formData.name,
                email: formData.email,
                country: formData.country,
                phone: formData.phone,
                message: formData.message
            });

            if (response.status === 200) {
                setResponseMessage('MESSAGE SENT');
            } else {
                setResponseMessage('Failed to send message.');
                setSubmissionDisabled(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage('Error submitting the form. Please try again later.');
            setSubmissionDisabled(false);
        } finally {
            setIsSubmitting(false);
            setTimeout(() => setSubmissionDisabled(false), 10000); // Enable button after 10 seconds
        }
    };

    return (
        <>
            <section className="contact_page_section" id="contact">
                <div className="container">
                    <div className="contact_inner">
                        <div className="contact_form">
                            <div className="section_title">
                                <h2>Leave a <span>message</span></h2>
                                <p>Fill out the form below, and our team will get back to you as soon as possible.</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        className="form-control"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                        disabled={submissionDisabled}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                        disabled={submissionDisabled}
                                    />
                                </div>
                                <div className="form-group">
                                    <select
                                        name="country"
                                        className="form-control"
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        required
                                        disabled={submissionDisabled}
                                    >
                                        <option value="">Select Country</option>
                                        <option value="Canada">Canada</option>
                                        <option value="USA">USA</option>
                                        <option value="UK">UK</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder="Phone"
                                        className="form-control"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        disabled={submissionDisabled}
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        className="form-control"
                                        placeholder="Your message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        required
                                        disabled={submissionDisabled}
                                    ></textarea>
                                </div>
                                <div className="form-group term_check">
                                    <input
                                        type="checkbox"
                                        name="agree"
                                        id="term"
                                        checked={formData.agree}
                                        onChange={handleInputChange}
                                        disabled={submissionDisabled}
                                    />
                                    <label htmlFor="term">I agree to receive emails, newsletters, and promotional messages.</label>
                                </div>
                                <div className="form-group mb-0">
                                    <button type="submit" className="btn puprple_btn" disabled={submissionDisabled}>
                                        {isSubmitting ? "SENDING..." : "SEND MESSAGE"}
                                    </button>
                                </div>
                                {responseMessage && (
                                    <p style={{ fontSize: "20px", fontWeight: "bold", color: responseMessage === 'MESSAGE SENT' ? 'green' : 'red' }}>
                                        {responseMessage}
                                    </p>
                                )}
                            </form>
                        </div>
                        <div className="contact_info">
                            <div className="icon"><img src={Messageicon} alt="Contact Message Icon" /></div>
                            <div className="section_title">
                                <h2>Have any <span>questions?</span></h2>
                                <p>If you have any questions about our product, service, payment, or company, visit our <Link to="/faq">FAQs page.</Link></p>
                            </div>
                            <Link to="/faq" className="btn puprple_btn">READ FAQ</Link>
                            <ul className="contact_info_list">
                                <li>
                                    <div className="img">
                                        <img src={Mailicon} alt="Email Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <Link to="mailto:support@misoulmate.com">support@misoulmate.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Callicon} alt="Call Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <Link to="tel:+12896391765">+1 (289) 639-1765</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Locationicon} alt="Location Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Visit Us</span>
                                        <p>1 Hunter St E, Suite G100, Hamilton, ON L8N 3W1</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Leave;
